import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Nav from './components/Nav'
import { AuthProvider } from './context/AuthProvider';
import AuthLayout from './layout/AuthLayout';
import RutaProtegida from './layout/RutaProtegida';
import RutaProtegidaAdmin from './layout/RutaProtegidaAdmin';
import Admin from './pages/Admin';
import Cliente from './pages/Cliente';
import NotFound from './pages/NotFound';
import Summary from './pages/Summary';
import DashboardAdmin from './pages/DashboardAdmin';
import Login from './pages/Login';
import DashboardClient from './pages/DashboardClient';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    if (window.location.pathname === "/login" || window.location.pathname === "/") {
      window.onpopstate = (e) => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("client_id");
      };
    }
  }, []);

  return (
    <Router>
      <AuthProvider>
        <Nav />
        <Routes>
          <Route path="/" element={<AuthLayout />} />
          <Route path="/login" element={<Login />} />
          <Route element={<RutaProtegida />}>
            <Route path="/client/dashboard" element={<DashboardClient />} />
            <Route path="/client/accounts" element={<Cliente />} />
          </Route>
          <Route element={<RutaProtegidaAdmin />}>
            <Route path="/admin/dashboard" element={<DashboardAdmin />} />
            <Route path="/admin/clients" element={<Admin />} />
            <Route path="/admin/:accountId" element={<Summary />} />
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
