import { Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts'
import { currencyFormatter } from '../utils/utils';

const DonnutChartComponent = ({ title, colors, data, loading }) => {

  const isNoData = () => {
    let nullsArray = data && data.length && data.map((d) => d.data === 0)
    return nullsArray[0]
  }

  const [series, setSeries] = useState([])

  const options = {
    chart: {
      type: 'donut',
      dropShadow: {
        enabled: true,
        color: '#111',
        top: -1,
        left: 3,
        blur: 3,
        opacity: 0.05
      },
      width:280
    },
    stroke: {
      width: 0,
    },
    dataLabels: {
      enabled: false,

    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: isNoData() ? false : true,
            value: {
              show: true,
              fontSize: '14px',
              offsetY: 0,
            },
            total: {
              showAlways: true,
              fontSize: '14px',
              show: true,
              formatter: function (val) {
                return isNoData() ? 
                  ""
                : currencyFormatter({
                  currency: "USD", value: val.config.series.reduce(function (a, b) {
                    return a + b;
                  }, 0)
                })
              }
            }
          }
        }
      }
    },
    labels: isNoData() ? 
    ["No hay info", "No hay info"]
    : data && data.length ? data.map(d => (`N° ${d.name}`)) : [],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 280
        },
      }
    }],
    tooltip: {

      y: {
        formatter: function (val) {

          return isNoData() ? "" :
          currencyFormatter({ currency: "USD", value: val })
        }
      },
    },
    colors: isNoData() ? ["#ebebeb", "#dcdcdc"] : colors
  }

  useEffect(() => {
    if (data && data.length) {
      setSeries(data.map(d => (d.data)))
    }
  }, [data])

  return (
    <Paper sx={{ display: "flex", width: {xs: "280px", md:"450px"}, height: {xs: "140px", md:"180px"}, borderRadius: "10px", padding: "20px", alignItems: "center", overflow: "hidden", justifyContent: "center", boxShadow: "0 1px 6px rgba(0, 0, 0, .08)", flexDirection: "column", gap: 1 }}>
      <Typography
        sx={{
          alignSelf: "start",
          fontFamily: "Montserrat",
          letterSpacing: 1,
          fontWeight: "bold",
          color: "#53595d",
          fontSize: {xs: "12px", md:"15px"}
        }}
        variant="body1">
        {title}
      </Typography>
      {!loading && <div id="chart" >
        <Chart options={options} series={isNoData() ? [1, 10] : series} type="donut" width={"280px"}/>
      </div>}
      <div id="html-dist"></div>
    </Paper>
  );
}

export default DonnutChartComponent;