import {
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { API_NOGLAXMAN } from "../utils/config";
import { ToastContainer, toast } from "react-toastify";
import ButtonNoglax from "./ButtonNoglax";

const FormNewAccount = ({ client, searchAccountOfClient }) => {

  const [dataAccount, setDataAccount] = useState('');
  const [loading, setLoading] = useState(false);

  const dataNewAccount = {
    clientID: client.id,
    account_id: parseInt(dataAccount)
  }

  const token = window.localStorage.getItem('token');

  const createAccount = async () => {
    try{
        const resolve = await fetch(`${API_NOGLAXMAN}/account`, {
          method: "POST",
          body: JSON.stringify(dataNewAccount),
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`},
        });
        const data = await resolve.json();
        if (data.message){
          searchAccountOfClient(client.id);
          setDataAccount('')
          setLoading(false)
          Swal.fire({
            text: "Cuenta agregada con éxito!",
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: "#1b9e17",
            background: "#eaeaea",
            backdrop: "rgba(0, 0, 0, 0.8)"
          })
        }
    } catch (error) {
        setLoading(false)
        Swal.fire({
          title: 'Error!',
          text: "No se pudo crear la cuenta.",
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
  };

  const handleSumbit = async (e) =>{
    e.preventDefault()
    if ([dataAccount].includes("")) {
      toast.warn("Ingrese un número de cuenta", {position: "bottom-right",});
      return;
    }else{ 
      setLoading(true)
      await createAccount();
    }
  }

  const handleInput = (event) => {
    setDataAccount(event.target.value);
  };

  return (
        <form action="" onSubmit={handleSumbit} style={{display: "flex", alignItems: "center", justifyContent:"space-between", gap: 20, width: "100%"}}>
            <TextField
              size="small"
              color="primary"
              sx={{ maxWidth: "70%" }}
              label="Numero de cuenta *"
              variant="outlined"
              value={dataAccount}
              onChange={handleInput}
              name="account"
              fullWidth
              type='number'
            />
            <ButtonNoglax
              style={{width: "20%"}}
              title={"Agregar cuenta"}
              variant="contained"
              type="submit"
              size={"medium"}
              loading={loading}
            />
            <ToastContainer position="bottom_left"/>
        </form>
  );
};

export default FormNewAccount;
