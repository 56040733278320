import { Box, Typography } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const InfoSeguros = () => {
  return (
    <Box sx={{
      padding: "24px",
      fontSize: "14px",
      height: "24rem",
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: "0 0 10px 0px rgba(0,0,0,0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      maxWidth: "300px",
      minWidth: "260px",
    }}>
      <Box sx={{ marginBottom: "16px" }}>
        <Typography
          align="center"
          style={{ color: "#4F5665", fontFamily: '"Rubik", sans-serif', fontSize: "18px", fontWeight: 400 }}
        >Seguro</Typography>
        <Box>
          <AdminPanelSettingsIcon
            sx={{
              mt: "16px",
              padding: "20px",
              color: "rgb(164,33,230)",
              height: "60px",
              width: "60px",
              borderRadius: "50%",
              backgroundColor: "rgba(164,33,230,0.1)",
              // boxShadow: "0 0 0.9px #000"
            }}
          />
        </Box>
      </Box>
      <Box sx={{ marginBottom: "24px" }}>
        <Typography
          variant="body1"
          align='justify'
          sx={{
            color: "#4F5665", fontFamily: '"Rubik", sans-serif', fontSize: "16px", fontWeight: 200,
            marginTop: "16px",
          }}
        >
          La empresa ofrece tanto un seguro de inversión como una garantía de
          prestación cambiaria. Estos seguros son aportados tanto como por
          NOGLAXMAN IT, DUOMARKETS y también por nuestro CEO, abalados por el
          broker utilizado y el BCRA.
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoSeguros;
