import { CircularProgress, Divider, IconButton, List, ListItemButton, ListItemText, Paper, Slide, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { API_NOGLAXMAN } from "../utils/config";
import { capitalize } from "../utils/utils";
import FormNewAccount from "../components/FormNewAccount";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Summary from "./Summary";
import AddIcon from '@mui/icons-material/Add';
import ButtonNoglax from "../components/ButtonNoglax";
import noDataImg from "../images/svg-person/no-data-user.svg"

const DetailsAccount = ({
  buscarCuentas,
  client_id,
  name,
  lastname,
  handleClickOpenNewAccount,
  setOpenSummary,
  openSummary
}) => {

  const [accounts, setAccounts] = useState()

  const [clientNewAccount, setClientNewAccount] = useState({})

  const [loadingAccounts, setLoadingAccounts] = useState(false)

  const [currentAccount, setCurrentAccount] = useState()

  const [openAddSummary, setOpenAddSummary] = useState(false)

  const token = window.localStorage.getItem("token");

  const searchAccountOfClient = async () => {
    setLoadingAccounts(true)
    const resolve = await fetch(`${API_NOGLAXMAN}/client/accounts/${client_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await resolve.json();
    setAccounts(data)
    setLoadingAccounts(false)
  }

  const confirmDelete = async (account_id, client_id) => {
    await deleteAccount(account_id);
    searchAccountOfClient();
  }

  const deleteAccount = async (id) => {
    try {
      const resolve = await fetch(`${API_NOGLAXMAN}/account/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await resolve.json()
      if (data) {
        return ('Cuenta eliminada con éxito')
      }
    }
    catch (error) {
      return (error)
    }
  };

  useEffect(() => {
    searchAccountOfClient();
    setClientNewAccount({
      id: client_id,
      name: name,
      lastname: lastname,
    })
  }, [])

  return (
    <>
      <Slide direction="left" in={openSummary} mountOnEnter unmountOnExit sx={{ position: "absolute", height: "100%", width: "100%", zIndex: 1000 }}>
          <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", margin: "10px", gap: 10 }}>
              <div style={{display: "flex", justifyContent: "center", alignItems:"center", gap: 10}}>
              <IconButton
                type="button" sx={{ p: '10px' }}
                aria-label="search"
                onClick={() => {
                  setOpenSummary(false)
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            <Typography variant="h6"> {`Resumenes de la cuenta ${currentAccount}`}</Typography>
                </div>
            <ButtonNoglax
              StartIcon={AddIcon}
              variant=""
              title={"Agregar resumen"}
              size="medium"
              type="button"
              sx={{ marginRight: "20px" }}
              onClick={() => {
                setOpenAddSummary(true)
              }}
            />
            </div>
                
            <Divider></Divider>
          <div style={{ display:"flex", justifyContent: "center", alignItems:"center", height: "calc(100% - 70px)"}}>
            <Summary accountId={currentAccount} setOpenAddSummary={setOpenAddSummary} openAddSummary={openAddSummary} />
            </div>
            {/* <FormCliente updateClient={updateClient} client={client} handleClose={handleClose} getAllClient={getAllClient} setOpen={setOpen} /> */}
            {/* <TabsComponent updateClient={updateClient} client={client} handleClose={handleClose} getAllClient={getAllClient} setOpen={setOpen} /> */}
          </Paper>
        </Slide>
      {/* </div> */}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: "90%", gap: "15%", width: "100%" }}>
        <div style={{ width: "80%", border: "2px solid #eee", borderRadius: "10px", overflow: "hidden" }}>
          <Typography sx={{ padding: "10px", fontSize: "18px", backgroundColor: "#eee" }}>
            Cuentas de {capitalize(lastname.toLowerCase())} {capitalize(name.toLowerCase())}
          </Typography>
          <div style={{ maxHeight: "100%", overflowY: "scroll" }}>
            <List
              sx={{ bgcolor: 'background.paper', borderRadius: "0px 0px 10px 10px" }}
            >
              {!loadingAccounts && accounts && accounts.length ? (accounts.map((account, index) => {
                return (
                  <>
                    <ListItemButton
                      alignItems="flex-start"
                      key={index}
                      divider={index !== accounts.length - 1}
                      onClick={() => {
                        setOpenSummary(true)
                        setCurrentAccount(account.account_id)
                      }}
                    >
                      <ListItemText
                        primary={
                          <>
                            <Typography
                              sx={{ display: 'inline', fontFamily: "30px" }}
                              component="span"
                              color="text.primary"
                            >
                              Numero de cuenta:
                            </Typography>
                            <Typography
                              sx={{ display: 'inline', fontFamily: "30px" }}
                              component="span"
                              color="text.primary"
                            >
                              {` ${account.account_id}`}
                            </Typography>
                          </>
                        }
                      />
                      <IconButton
                        type="button"
                        onClick={async (e) => {
                          e.stopPropagation()
                          Swal.fire({
                            text: `¿Desea eliminar la cuenta ${account.account_id}?`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Aceptar",
                            cancelButtonText: "Cancelar",
                            cancelButtonColor: "#aB0e0e",
                            confirmButtonColor: "#1b9e17",
                            background: "#eaeaea",
                            backdrop: "rgba(0, 0, 0, 0.8)",
                            reverseButtons: true
                          }).then((result) => {
                            if (result.isConfirmed) {
                              confirmDelete(account.account_id, client_id);
                              Swal.fire({
                                text: "Cuenta eliminada con éxito!",
                                icon: "success",
                                confirmButtonText: "Ok",
                                confirmButtonColor: "#1b9e17",
                                background: "#eaeaea",
                                backdrop: "rgba(0, 0, 0, 0.8)",
                              });
                            }
                          });
                        }}>
                        <DeleteIcon />
                      </ IconButton>
                    </ListItemButton>
                  </>
                )
              })) : !loadingAccounts && accounts && !accounts.length ? (
              <div style={{  width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={noDataImg} style={{ width: "200px", height: "200px" }} alt="img no data"/>
              </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                  <CircularProgress size={20} color="secondary" />
                </div>
              )
              }
            </List>
          </div>
        </div>
        <div style={{ width: "80%" }}>
          <div style={{ display: "flex", marginBottom: "30px", alignItems: "center", width: "100%" }}>
            <Divider sx={{ flex: "1" }} />
            <Typography sx={{ color: "#a7a7a7", marginLeft: "10px" }}>Agregar nueva cuenta</Typography>
            <Divider sx={{ flex: "35", marginLeft: "10px" }} />
          </div>
          <FormNewAccount
            client={clientNewAccount}
            searchAccountOfClient={searchAccountOfClient}
          />
        </div>
      </div>
    </>
    // <Grid container xs={12}
    // spacing={4}
    // direction="row"
    // justifyContent="center"
    // alignItems="center"
    // sx={{height: "100%"}}>
    //   {accounts && accounts.length && accounts.map((account) => {
    //     return (
    //       <Grid item >
    //         <Card sx={{display: "flex", width: "200px", height: "100px", textAlign: "center", justifyContent: "center"}}>
    //           <Typography
    //             sx={{
    //               fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "18px" },
    //               color: "#666",
    //             }}
    //           >
    //             {account.account_id}
    //           </Typography>
    //         </Card>
    //       </Grid>
    //     )
    //   })}
    // </Grid>
    // <List sx={{ width: "100%" }} component="nav" aria-label="mailbox folders">
    //   <ListItemButton
    //     divider
    //     sx={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       backgroundColor:'#eaeaea',
    //     }}
    //   >
    //      <a
    //       href={`/admin/${account.account_id}`}
    //       style={{
    //         width: "100%",
    //         color: "#000",
    //         textDecoration: "none",
    //         ":link": { textDecoration: "none" },
    //         ":active": { textDecoration: "none" },
    //         ":visited": { textDecoration: "none" },
    //       }}>
    //       <Typography
    //         sx={{
    //           fontSize: {xs: "12px", sm: "14px", md: "16px", lg: "18px"},
    //           display: "inline",
    //           fontWeight: "bold",
    //         }}
    //       >
    //         Nro. cuenta
    //       </Typography>
    //       <Typography
    //         sx={{
    //           fontSize: {xs: "12px", sm: "14px", md: "16px", lg: "18px"},
    //           display: "inline",
    //           fontWeight: "700",
    //           color: "#666",
    //         }}
    //       >
    //         : {account.account_id}
    //       </Typography>
    //     </a>
    //     <DeleteIcon
    //       sx={{
    //         fontSize: {xs: "18px", sm: "20px", md: "22px", lg: "24px"},
    //         color: "#000",
    //         transition: "0.1s",
    //         ":hover": {
    //           color: "#d32f2f",
    //           transform: "scale(1.15)",
    //         },
    //       }}
    //       onClick={async () => {
    //         Swal.fire({
    //           text: `¿Desea eliminar la cuenta ${account.account_id}?`,
    //           icon: "warning",
    //           showCancelButton: true,
    //           confirmButtonText: "Aceptar",
    //           cancelButtonText: "Cancelar",
    //           cancelButtonColor: "#aB0e0e",
    //           confirmButtonColor: "#1b9e17",
    //           background: "#eaeaea",
    //           backdrop: "rgba(0, 0, 0, 0.8)",
    //           reverseButtons: true
    //         }).then((result) => {
    //           if (result.isConfirmed) {
    //             confirmDelete(account.account_id, client_id);
    //             Swal.fire({
    //               text: "Cuenta eliminada con éxito!",
    //               icon: "success",
    //               confirmButtonText: "Ok",
    //               confirmButtonColor: "#1b9e17",
    //               background: "#eaeaea",
    //               backdrop: "rgba(0, 0, 0, 0.8)",
    //             });
    //           }
    //         });
    //       }}
    //     ></DeleteIcon>
    //   </ListItemButton>
    // </List>
  );
};

export default DetailsAccount;
