import { Box, Divider, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FormCliente from "./FormCliente";
import DetailsAccount from "../pages/DetailsAccount";

function CustomTabPanel(props) {
  const { children, value, index, openSummary, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {value === index && (
        <div style={{ p: 3, height: openSummary ? "100%" : "calc(100% - 70px)", display: !openSummary && "flex", justifyContent: !openSummary && "center", alignItems: !openSummary && "center"}}>
          {children}
        </div>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabsComponent = ({ updateClient, setOpen, getAllClient, client, handleClose, handleClickOpenNewAccount, setClientNewAccount }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [id, setId] = useState("");
  const [dni, setDni] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");

  const [openSummary, setOpenSummary] = useState(false)

  useEffect(() => {
    if (updateClient && client) {
      setId(client.client_id)
      setDni(client.dni)
      setName(client.name)
      setLastname(client.lastname)
      setUsername(client.username)
      // setPass(client.pass)
    }
    if (!updateClient) {
      setId("")
      setDni("")
      setName("")
      setLastname("")
      setUsername("")
      setPass("")
    }
  }, [client, updateClient])

  return (
    <Box sx={{ width: '100%', height: "100%" }}>
      {!openSummary ? (
        <>
          <div style={{ display: "flex", justifyContent: "start", width: "100%", alignItems: "center", margin: "10px", gap: 10 }}>
            <IconButton
              type="button" sx={{ p: '10px' }}
              aria-label="search"
              onClick={() => {
                setOpen(false)
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Typography variant="h6"> {updateClient ? 'Editar cliente' : 'Agregar cliente'}</Typography>
            <Box sx={{ display: "flex", justifyContent: "end", width: "80%" }}>
              <Tabs 
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                textcolor="primary"
                indicatorcolor="primary"
                sx={{
                  display: {
                    xs: "none", md: "flex",
                    letterSpacing: 1,
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: "rgb(164, 33, 230)",
                    fontFamily: "'Poppins', sans-serif",
                  },
                  "& .MuiTab-root": {
                    fontFamily: "'Poppins', sans-serif",
                  }
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "rgb(164, 33, 230)",
                    color: "rgb(164, 33, 230)",
                  },
                }}  
              >
                <Tab 
                label="Información personal" {...a11yProps(0)}
                style={{ fontWeight: "bold" }}
                />
                {updateClient && <Tab label="Cuentas" {...a11yProps(1)} style={{ fontWeight: "bold" }} />}
              </Tabs>
            </Box>
          </div>
          <Divider></Divider>
        </>
      ) : null}
      <CustomTabPanel value={value} index={0}>
        <FormCliente
          updateClient={updateClient}
          client={client}
          getAllClient={getAllClient}
          handleClose={handleClose}
          id={id}
          dni={dni}
          name={name}
          lastname={lastname}
          username={username}
          pass={pass}
          setId={setId}
          setDni={setDni}
          setName={setName}
          setLastname={setLastname}
          setUsername={setUsername}
          setPass={setPass}
        />
      </CustomTabPanel>
      {updateClient &&
        <CustomTabPanel value={value} index={1} openSummary={openSummary}>
          <DetailsAccount
            handleClickOpenNewAccount={handleClickOpenNewAccount}
            client_id={client.client_id}
            name={name}
            lastname={lastname}
            setOpenSummary={setOpenSummary}
            openSummary={openSummary}
          />
        </CustomTabPanel>
      }
    </Box>
  );
}

export default TabsComponent;