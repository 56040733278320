import { Box, Typography } from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const InfoRentabilidad = () => {
  return (
    <Box sx={{
      padding: "24px",
      fontSize: "14px",
      height: "24rem",
      maxWidth: "300px",
      minWidth: "260px",
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: "0 0 10px 0px rgba(0,0,0,0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    }}>
      <Box sx={{ marginBottom: "16px" }}>
        <Typography
          align="center"
          style={{ color: "#4F5665", fontFamily: '"Rubik", sans-serif', fontSize: "18px", fontWeight: 400 }}
        >Rentabilidad</Typography>
        <Box>
          <AttachMoneyIcon
            sx={{
              mt: "16px",
              padding: "20px",
              color: "rgb(164,33,230)",
              height: "60px",
              width: "60px",
              borderRadius: "50%",
              backgroundColor: "rgba(164,33,230,0.1)",
              // boxShadow: "0 0 0.9px #000"
            }}
          />
        </Box>
      </Box>
      <Box>
        <Typography
          variant="body1"
          align='justify'
          sx={{
            color: "#4F5665", fontFamily: '"Rubik", sans-serif', fontSize: "16px", fontWeight: 200,
            marginTop: "16px",
          }}
        >
          La rentabildad se efectúa en el contrato según los términos aplicados en tal. Contamos con un porcentaje estable de aproximadamente un 86.6% de efectividad, porcentaje obtenido por cuentas fondeadas con más de 6 meses invirtiendo mes a mes con una estrategia sólida y confiable.
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoRentabilidad;