import { Box, CircularProgress, Paper, Typography } from "@mui/material";

const GraphicsCount = ({ title, title2, Icon, amount, img, iconBackground, iconColor, width = "60%", loading }) => {
  return (
    <Paper sx={{ display: "flex", width: {xs: "280px", md:"450px"}, height: {xs: "140px", md:"180px"}, borderRadius: "10px", padding: "20px", alignItems: "center", overflow: "hidden", justifyContent: "space-between", boxShadow: "0 1px 6px rgba(0, 0, 0, .08)" }}>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", alignItems: "center" }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: {xs: "45px", md:"70px"}, width: {xs: "45px", md:"70px"}, borderRadius: "100%", backgroundColor: iconBackground, boxShadow: "0 1px 2px rgba(0, 0, 0, .01)" }}>
          <Icon sx={{ color: iconColor, fontSize: {xs:"30px",md:"45px"} }} />
        </Box>
        {loading ? (
          <CircularProgress size={30}
            sx={{ color: iconColor }}
          />
        ) : (
          <Typography variant="h4" sx={{
            fontFamily: "Montserrat",
            fontSize: {xs: "22px", md:"30px"}
          }}>
            {amount}
          </Typography>
        )}
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="body1"
            sx={{
              fontFamily: "Montserrat",
              letterSpacing: 1,
              fontWeight: "bold",
              color: "#53595d",
              fontSize: {xs: "12px", md:"15px"},
              textWrap: "noWrap"
            }}
          >{title}</Typography>
          {title2 && (
            <Typography variant="body1"
              sx={{
                fontFamily: "Montserrat",
                letterSpacing: 1,
                fontWeight: "bold",
                color: "#53595d",
                fontSize: {xs: "11px", md:"15px"}
              }}
            >{title2}</Typography>
          )}
        </div>
      </div>
      <div style={{ width }}>
        <img src={img} alt="Graphics" style={{ width: "100%" }} />
      </div>
    </Paper>
  );
}

export default GraphicsCount;