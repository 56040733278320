import Swal from 'sweetalert2'
import {
  TextField,
  Box,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { API_NOGLAXMAN } from '../utils/config';
import ButtonNoglax from './ButtonNoglax';

const FormLogin = ({ open, setOpen, handleClose }) => {

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const { setAuth } = useAuth();

  const [dataUser, setDataUser] = useState({
    username: "",
    pass: "",
  });

  const loginClient = async (e) => {
    e.preventDefault()
    try {
      const resolve = await fetch(`${API_NOGLAXMAN}/auth/login`, {
        method: "POST",
        body: JSON.stringify(dataUser),
        headers: { "Content-Type": "application/json" },
      });
      const data = await resolve.json();
      window.localStorage.setItem("token", data.tokenSession);
      if (data.data) {
        setLoading(false)
        setDataUser({
          username: "",
          pass: "",
        });
        setAuth(data.data);
        window.localStorage.setItem('client_id', data.data.client_id)
        window.localStorage.setItem('name', data.data.name)
        window.localStorage.setItem('lastname', data.data.lastname)
        const result = await fetch(`${API_NOGLAXMAN}/client/role`, {
          method: "POST",
          body: JSON.stringify({ token: data.tokenSession }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.tokenSession}`,
          },
        });
        const role = await result.json();
        if (role.role === "user") {
          navigate("/client/dashboard");
        } else {
          navigate("/admin/dashboard");
        }
      }
      if (data.error === "Contraseña incorrecta") {
        setLoading(false)
        Swal.fire({
          text: "Los datos ingresados no son correctos.",
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: "#f44336",
          background: "#eaeaea",
          backdrop: "rgba(0, 0, 0, 0.8)"
        }).then((result) => {
        })
      }
      if (data.error && data.error.code === "ECONNREFUSED") {
        setLoading(false)
        Swal.fire({
          text: "Error en el servidor",
          icon: 'warning',
          confirmButtonText: 'Ok',
          confirmButtonColor: "#f44336",
          background: "#eaeaea",
          backdrop: "rgba(0, 0, 0, 0.8)"
        }).then((result) => {
        })
      }
    } catch (error) {
      setLoading(false)
      Swal.fire({
        title: 'Error!',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    }
  };

  const handleInput = (event) => {
    setDataUser({
      ...dataUser,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Box sx={{padding: "80px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: "28px", lg: "20px" },
          fontFamily: '"Rubik", sans-serif',
          color: "rgb(164, 33, 230)",
          textDecoration: "none",
        }}
      >
        Inicie sesión
      </Typography>
      <Box>
        <TextField
          size="small"
          color="secondary"
          sx={{ mt: 2, mb: 2, width: "100%" }}
          label="Usuario"
          variant="outlined"
          onChange={handleInput}
          name="username"
          fullWidth
          required
        />
        <TextField
          size="small"
          sx={{ mt: 2, mb: 2, width: "100%" }}
          color="secondary"
          label="Contraseña"
          variant="outlined"
          onChange={handleInput}
          name="pass"
          fullWidth
          required
          type="password"
        />
        <ButtonNoglax
          variant="contained"
          title="Ingresar"
          type="submit"
          style={{ width: "100%", fontWeight: 500, fontFamily: '"Rubik", sans-serif' }}
          onClick={(e) => { 
            setLoading((!dataUser.username && dataUser.pass) || (dataUser.username && !dataUser.pass) || (!dataUser.username && !dataUser.pass) ? (false) : (true)) 
            loginClient(e)
          }}
          loading={loading}
        />
      </Box>
      {/* </DialogActions> */}
    </Box>
  );
};

export default FormLogin;
