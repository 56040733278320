// PROD
export const API_NOGLAXMAN = process.env.REACT_APP_API_NOGLAXMAN_URL
export const API_NOGLAXMAN_URL = process.env.REACT_APP_API_NOGLAXMAN_URL
export const API_NOGLAXMAN_PORT = process.env.REACT_APP_API_NOGLAXMAN_PORT
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
export const FIREBASE_PROJECT_ID= process.env.REACT_APP_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
export const EMAILJS_SERVICE_ID = process.env.REACT_APP_SERVICE_ID
export const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID
export const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY

// DEV
// export const API_NOGLAXMAN_PORT = process.env.REACT_APP_API_NOGLAXMAN_PORT
// export const API_NOGLAXMAN = `${process.env.REACT_APP_API_NOGLAXMAN_URL}:${API_NOGLAXMAN_PORT}`
// export const API_NOGLAXMAN_URL = process.env.REACT_APP_API_NOGLAXMAN_URL
// export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
// export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
// export const FIREBASE_PROJECT_ID= process.env.REACT_APP_FIREBASE_PROJECT_ID
// export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
// export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
// export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
// export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// export const EMAILJS_SERVICE_ID = process.env.REACT_APP_SERVICE_ID
// export const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID
// export const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY