import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import ReactCardCarousel from 'react-card-carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const Carrousel = ({ data }) => {
  const [carousel, setCarousel] = useState()
  return (
    <ReactCardCarousel ref={Carousel => setCarousel(Carousel)}>
      {data.map((operator, index) => (
        <Grid key={index} container sx={{
          height: {xs:"580px",sm:"610px",md:'400px', lg:'500px'},
          width: { xs: "350px", sm: "450px", md:'800px', lg:'1200px'},
          backgroundColor: "#122f47",
          borderRadius: '10px',
          overflow:"hidden"
        }}>
          <Grid item xs={1}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <IconButton onClick={() => carousel.prev()}><ArrowBackIosIcon sx={{ color: "rgb(255, 255, 255, 0.6)", fontSize: {sx:16, sm:18, md:30, lg:40} }} /></IconButton>
            </div>
          </Grid>
          <Grid item container xs={10}>
              <Grid item xs={12} md={4} sx={{display: "flex", padding: {xs:"6px", md:"0px", lg:"10px"}, justifyContent:"center", alignItems:"center"}}>
                <Box sx={{ height: "100%", width: {xs:"60%", md:"100%"}, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img src={operator.img} style={{ borderRadius: "10px", width:"100%" }} alt={`img de ${operator.name}`}/>
                </Box>
              </Grid>
              <Grid item xs={10} md={8}>
              <Box sx={{ width: "100%", height: "100%", display: "flex", padding: { xs: "18px", md: "24px", lg: "12px" }, flexDirection: "column"}}>
                {/* ultimo 9685ee */}
                <Typography sx={{ fontFamily: '"Rubik", sans-serif', color: "#ad6ad9", fontSize: { xs: "14px", md:"20px", lg:"30px"}, fontWeight:500}} align='center'>{operator.title}</Typography>
                  <ul>
                  <Typography sx={{ fontFamily: '"Rubik", sans-serif', color: "#cacad5", fontSize: { xs: "14px",md: "18px", lg: "28px" }, fontWeight: 500, marginBottom: {md:"12px", lg: "24px"} }} align='start'>{operator.name}</Typography>
                    {operator.skills.map((skill, index) => (
                      <li key={index} style={{ color: "#ad6ad9", marginBottom: {xs:"0px", md:"10px"} }}><Typography sx={{ fontFamily: '"Rubik", sans-serif', color: "#cacad5", fontSize: { xs: "10px", md: "14px", lg: "18px" } }} align='start'>{skill}</Typography></li>
                    ))}
                  </ul>
                </Box>
              </Grid>
          </Grid>
          <Grid item xs={1}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <IconButton onClick={() => carousel.next()}><ArrowForwardIosIcon sx={{ color: "rgba(255 ,255 ,255, 0.6)", fontSize: { sx: 16, md: 30, lg: 40 } }} /></IconButton>
            </div>
          </Grid>
        </Grid>
      ))}
    </ReactCardCarousel>
  );
}

export default Carrousel;