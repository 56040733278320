import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { API_NOGLAXMAN } from "../utils/config.js";
import noDataImg from "../images/svg-person/no-data-user.svg"
import Loading from "../components/Loading.jsx";
import { Grid, Paper, Typography } from "@mui/material";
import TableClient from "../components/TableClient.jsx";

const Cliente = () => {

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [accountClick,setAccountClick] = useState(null)

  const client_id = window.localStorage.getItem('client_id')

  const token = window.localStorage.getItem("token");

  useEffect(() => {
    if (window.location.pathname === "/client") {
      window.onpopstate = (e) => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("client_id");
      };
    }
  }, []);

  const searchAccounts = async () => {
    setLoading(true)
    const resolve = await fetch(`${API_NOGLAXMAN}/client/accounts/${client_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Baerer ${token}`,
      },
    });
    const data = await resolve.json();
    if (data) {
      setAccounts(data);
    }
    setLoading(false)
  };

  useEffect(() => {
    searchAccounts();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fdfdfd",
        height: "calc(100vh - 64px)",
        flexDirection: "column"
      }}
    >
      {
        loading && accounts && !accounts.length ? (
          <div style={{width: '100%', height: 'calc(100vh - 64px)', display:'flex', justifyContent:'center', alignItems:'center'}}><Loading height='180px' width="180px"/></div>
        ) : !loading && accounts && !accounts.length ? (
          <div style={{width: '100%', height: 'calc(100vh - 64px)', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:"column"}}>
            <img src={noDataImg} style={{ width: "300px", height: "300px" }} alt="No hay información" />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "bold",
                color: "#53595d",
                fontSize: {xs: "14px", md:"20px"}
              }}
            >
              No tenés cuentas a tu nombre en el sistema.
            </Typography>
          </div>
        ) : !loading && accounts && accounts.length && !open ? (
          <div style={{width: "90%"}}>
            <Grid container spacing={4} justifyContent={"center"} alignItems={"center"}>
              {accounts.map((account) => (
                <Grid 
                  item 
                  xs={8} key={account.account_id}
                  onClick={() => {
                    setOpen(true)
                    setAccountClick(account.account_id)
                  }}
                >
                  <Paper sx={{
                      width: "100%", 
                      height: "90px", 
                      display:"flex", 
                      justifyContent: "center", 
                      alignItems: "center",
                      // backgroundColor: "#4382b3",
                      // backgroundColor: "#5159b0",
                      backgroundColor: "#6861d0",
                      
                      borderRadius: "20px",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, .1)",
                      '&:hover': {
                        backgroundColor: '#7a73dc',
                        cursor: "pointer",
                        transform: "scale(1.02)",
                        transition: "all 0.3s ease-in-out"
                      }
                    }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontFamily: 'Montserrat',
                        fontSize: "22px",
                        color: "#fff",
                      }}
                    >
                      N° {account.account_id}
                    </Typography>
                  </Paper>
                </Grid>
            ))}
          </Grid>
          </div>
        ) : !loading && accounts && accounts.length && open ? 
          <TableClient setOpenAccount={setOpen} accountId={accountClick}/>
        : (null)
      }
    </div>
  );
};

export default Cliente;