import {
  Typography,
  Divider,
  Paper,
  Slide,
  IconButton,
  Box,
} from "@mui/material";
import * as React from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Summary from "../pages/Summary.jsx";

const TableClient = ({ setOpenAccount, accountId }) => {
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Paper sx={{ width: '90%', overflow: 'hidden', height: "90%", position: "relative" }}>
        <div style={{ display: "flex", justifyContent: "start", width: "100%", alignItems: "center", margin: "10px", gap: 10 }}>
          <IconButton
            type="button" sx={{ p: '10px' }}
            aria-label="search"
            onClick={() => {
              setOpenAccount(false)
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="h6">Cuenta {accountId}</Typography>
        </div>
        <Divider></Divider>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100% - 70px)", overflowY: "auto", marginTop: { xs: "10px", md: 0 } }}>
          <Summary accountId={accountId} isEdit={false} />
        </Box>
      </Paper>
    </Slide>
  );
};

export default TableClient;