import * as React from "react";
import { TextField, Divider, Grid } from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";
import { API_NOGLAXMAN } from "../utils/config";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BaseFormBtn from "./BaseFormBtn";

function FormCliente({ 
  updateClient,
  handleClose,
  getAllClient,
  id,
  dni,
  name,
  lastname,
  username,
  pass,
  setId,
  setDni,
  setName,
  setLastname,
  setUsername,
  setPass
}) {

  const [loading, setLoading] = useState(false);

  const token = window.localStorage.getItem('token');

  const createNewClient = async (newClient) => {
    try {
      const resolve = await fetch(`${API_NOGLAXMAN}/auth/register`, {
        method: 'POST',
        body: JSON.stringify(newClient),
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      });
      const data = await resolve.json();
      if (data.message) {
        setLoading(false)
        Swal.fire({
          text: "Cliente creado con éxito!",
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: "#1b9e17",
          background: "#eaeaea",
          backdrop: "rgba(0, 0, 0, 0.8)"
        })
      }
    } catch (error) {
      setLoading(false)
      Swal.fire({
        title: 'Error!',
        text: "El usuario ya existe.",
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    }
  }

  const updateNewClient = async (newClient) => {

    try {
      const resolve = await fetch(`${API_NOGLAXMAN}/client/${newClient.id}`, {
        method: 'PUT',
        body: JSON.stringify(newClient),
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      });
      const data = await resolve.json();
      if (data.message) {
        setLoading(false)
        Swal.fire({
          text: "Cliente actualizado con éxito!",
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: "#1b9e17",
          background: "#eaeaea",
          backdrop: "rgba(0, 0, 0, 0.8)"
        })
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: "No se pudo actualizar el cliente.",
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if ([dni, name, lastname, username].includes("")) {
      setLoading(false);
      toast.warn("Todos los campos son requeridos", {position: "bottom-right",});
      return;
    }

    const newClient = {
      id,
      dni,
      name: name[0].toUpperCase() + name.substring(1),
      lastname: lastname[0].toUpperCase() + lastname.substring(1),
      username,
      pass,
    }

    if (updateClient) {
      await updateNewClient(newClient)
      setLoading(false)
      getAllClient()
      handleClose()
    }
    else {
      await createNewClient(newClient)
      getAllClient()
      handleClose()
    }

    setId("")
    setDni("")
    setName("")
    setLastname("")
    setUsername("")
    setPass("")
  }

  return (
    <div>
      <form action="" style={{ width: "100%", height: 440, display: "flex", justifyContent: "center", alignItems:"center" }}>
        <Grid container lg={10} spacing={6}>
          <Grid item lg={6}>
            <TextField
              size="small"
              autoComplete="new-password"
              sx={{ width: "100%" }}
              type="number"
              label="DNI *"
              name="dni"
              value={dni}
              onChange={(e) => setDni(e.target.value)}
            />
          </Grid>
          <Grid item lg={6}>


            <TextField
              size="small"
              autoComplete="new-password"
              sx={{ width: "100%" }}
              type="text"
              label="Nombre *"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>

          <Grid item lg={6}>


            <TextField
              size="small"
              autoComplete="new-password"
              sx={{ width: "100%" }}
              type="text"
              label="Apellido *"
              name="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </Grid>

          <Grid item lg={6}>


            <TextField
              size="small"
              autoComplete="new-password"
              sx={{ width: "100%" }}
              type="text"
              label="Usuario *"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>

          <Grid item lg={6}>


            <TextField
              InputLabelProps={{ shrink: true }}
              inputProps={
                {
                  sx: {
                    '&::placeholder': {
                      fontSize: "10px",
                    },
                  },
                }
              }
              size="small"
              autoComplete="new-password"
              sx={{ width: "100%" }}
              type="password"
              id="outlined-multiline-flexible"
              label="Contraseña *"
              placeholder="●●●●●●●●●●"
              name="pass"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </Grid>
        </Grid>
      </form>
      <Divider></Divider>
      <div style={{display: "flex", justifyContent: "end", margin: "14px 0"}}>
        <BaseFormBtn 
          onClick={(e) => {
            setLoading(true)
            handleSubmit(e)
          }}
          title={updateClient ? "Actualizar" : "Agregar Cliente"}
          type="submit"
          loading={loading}
        />
      </div>
      <ToastContainer position="bottom_left"/>
    </div>
  );
}

export default FormCliente;