import { Grid, Typography } from "@mui/material";
import logo from "../images/svg-person/person_login.svg"
import FormLogin from "../components/FormLogin";

const Login = () => {
  return (
  <Grid
    container
    sx={{width: "100%", height: "100vh"}}
    sm={12}
  >
    <Grid 
      item
      sx={{ backgroundColor: "#fafafa",width:"100%", height:"100%", justifyContent: "center", alignItems:"center", flexDirection:"column", display:{xs:"none", md:"flex"}}}
      sm={7}  
    >
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: "28px", lg: "50px" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".10rem",
            color: "rgb(164, 33, 230)",
            textDecoration: "none",
            marginBottom:"60px",
            textShadow:"2px 2px 4px rgba(0, 0, 0, 0.1)"
          }}
        >
          NOGLAXMAN
        </Typography>
      <img src={logo} width={"60%"} alt="img logo"/>
    </Grid>
      <Grid
        item
        sx={{ backgroundColor: "#fff", height:"100%", display:"flex", justifyContent:"center", alignItems:"center" }}
        xs={12}
        md={5}
      >
        <FormLogin />
      </Grid>
    </Grid>);
}

export default Login;