import { Box, ImageList, ImageListItem, Typography } from "@mui/material";
import userInvest2 from "../images/svg-person/user_invest_2.svg"
import ButtonNoglax from "../components/ButtonNoglax"


const HomeV2 = () => {
  return (
    <div style={{
      height: 'calc(100vh - 64px)',
      width: '100%',
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <Box sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", md: "space-around" },
        flexDirection: { xs: "column", md: "row" },
        width: "80%"
      }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "start" }, zIndex: 3, flexDirection: "column" }}>
          <Typography textAlign={{ xs: "center", md: "start" }} sx={{ color: "#000", fontFamily: '"Rubik", sans-serif', fontSize: { xs: "30px", md: "46px", md: "30px", lg: "50px" }, width: { xs: "100%", md: "70%" } }}>
            Hacé crecer tu capital con <b>Noglaxman.</b>
          </Typography>
          <Typography textAlign={{ xs: "center", md: "start" }} sx={{ color: "#4F5665", fontFamily: '"Rubik", sans-serif', fontSize: { xs: "16px", md: "14px", lg: "18px" }, fontWeight: 200, marginTop: "20px", width: { xs: "100%", md: "70%" } }}>
            Si estas buscando un lugar para invertir y queres lograr una estabilidad economica a largo plazo, estas en el lugar correcto.
          </Typography>
          <a
            href="https://walink.co/b222ce"
            target="blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <ButtonNoglax
              size="large"
              style={{
                marginTop: "60px", fontWeight: "bold", fontFamily: '"Rubik", sans-serif', boxShadow: "0 3px 10px 0px rgb(164, 33, 230, 0.4)", display: { xs: "none", md: "flex" }
              }}
              title={"Comenzar ahora"}
              variant="contained"
            />
          </a>
        </Box>
        <Box sx={{
          // display: { sm: "none", md: "flex" },
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2
        }}>
          <ImageList sx={{ width: { md: 400, lg: 600 } }} cols={1}>
            <ImageListItem>
              <img src={userInvest2} style={{ zIndex: 3 }} />
            </ImageListItem>
          </ImageList>
        </Box>
        <a
          style={{textDecoration:"none"}}
          href="https://api.whatsapp.com/send?phone=+5491165905794&text=Hola!%20Quiero%20hacer%20crecer%20mi%20capital%20con%20Noglaxman%20%F0%9F%A4%9D.%20%20Me%20pasas%20info?%20Gracias!"
          target="blank"
          rel="noreferrer"
        >
          <ButtonNoglax
            size="large"
            style={{
              marginTop: "60px", fontWeight: "bold", fontFamily: '"Rubik", sans-serif', boxShadow: "0 3px 10px 0px rgb(164, 33, 230, 0.4)", display: { xs: "flex", md: "none" }
            }}
            title={"Comenzar ahora"}
            variant="contained"
          />
        </a>
      </Box>
    </div>
  );
}

export default HomeV2;
