import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";
import { uploadFile } from "../firebase/config";
import { API_NOGLAXMAN } from "../utils/config";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import ButtonNoglax from "./ButtonNoglax";
import { ToastContainer, toast } from "react-toastify";

const FormSummary = ({ open, handleClose, searchSummary, accountId }) => {
  const [file, setFile] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [profit, setProfit] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [withdraw, setWithdraw] = useState(null);
  const [summaryType, setSummaryType] = useState("");
  
  const [loading, setLoading] = useState(false);

  const token = window.localStorage.getItem('token');

  const createNewSummary = async (newSummary) => {
    try{
      const resolve = await fetch(`${API_NOGLAXMAN}/summary`, {
        method: "POST",
        body: JSON.stringify(newSummary),
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`},
      });
      const data = await resolve.json();
      return data;
    }
    catch(error){
      return (error)
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (([file, startDate, endDate, profit, summaryType, totalAmount].includes("")) || !profit || !summaryType || !totalAmount) {
      toast.warn("Todos los campos son requeridos", { position: "bottom-right", });
      setLoading(false);
      return;
    } else {
      const summary = await uploadFile(file);
      const newSummary = {
        accountID: accountId,
        summary_url: summary[1],
        summary_date_start: startDate,
        summary_date_end: endDate,
        profit: profit,
        total_amount: totalAmount,
        withdraw,
        type: summaryType
      };
      const data = await createNewSummary(newSummary);
      if (data){
        setLoading(false)
        Swal.fire({
          text: "Resumen creado con éxito!",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1b9e17",
          background: "#eaeaea",
          backdrop: "rgba(0, 0, 0, 0.8)",
        });
      }
      else{
        setLoading(false)
      }
      searchSummary(accountId);
      handleClose();
  
      setFile("")
      setStartDate("")
      setEndDate("")
      setProfit(null)
      setTotalAmount(null)
      setWithdraw(null)
      setSummaryType("")
    }

  }

  return (
    <Dialog open={open} onClose={handleClose} sx={{}}>
      <DialogTitle>Agregar resumen</DialogTitle>
      <DialogContent>
        <form action="" onSubmit={handleSubmit} style={{ width: 500, display: "flex", justifyContent: "center", gap: 20, flexDirection: "column" }}>
          <TextField
            sx={{ width: "100%" }}
            color="primary"
            size="small"
            type="file"
            name="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <DatePicker
            sx={{ width: "100%" }}
            size="small"
            label="Fecha inicial"
            format="YYYY/MM/DD"
            slotProps={{ textField: { size: 'small', color: "primary" } }}
            value={startDate ? moment(startDate, 'YYYY-MM-DD') : null}
            onChange={(value) => { setStartDate(value ? value.format('YYYY-MM-DD') : null) }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
          <DatePicker
            sx={{ width: "100%" }}
            label="Fecha final"
            format="YYYY/MM/DD"
            slotProps={{ textField: { size: 'small', color: "primary" } }}
            value={endDate ? moment(endDate, 'YYYY-MM-DD') : null}
            onChange={(value) => { setEndDate(value ? value.format('YYYY-MM-DD') : null) }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
          <FormControl sx={{ width: "100%", zIndex: 2000 }}>
            <InputLabel id="type-summary-label" color="primary" size="small">Tipo de resumen</InputLabel>
            <Select
              labelId="type-summary-label"
              id="type-summary"
              label="Tipo de resumen"
              size="small"
              color="primary"
              value={summaryType}
              onChange={(e) => setSummaryType(e.target.value)}
            >
              <MenuItem value={"short"}>Corto</MenuItem>
              <MenuItem value={"long"}>Largo</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ width: "100%" }}
            size="small"
            color="primary"
            type="number"
            id="outlined-required"
            label="Profit"
            name="profit"
            value={profit}
            onChange={(e) => setProfit(e.target.value)}
          />
          <TextField
            sx={{ width: "100%" }}
            size="small"
            color="primary"
            type="number"
            id="outlined-required"
            label="Monto retirado"
            name="withdraw"
            value={withdraw}
            onChange={(e) => setWithdraw(e.target.value)}
          />
          <TextField
            size="small"
            color="primary"
            sx={{ width: "100%" }}
            type="number"
            id="outlined-required"
            label="Monto total"
            name="ammount_total"
            value={totalAmount}
            onChange={(e) => setTotalAmount(e.target.value)}
          />
          <ButtonNoglax
            style={{ width: "100%" }}
            title={"Agregar resumen"}
            onClick={() => { setLoading(true) }}
            variant="contained"
            type="submit"
            size={"medium"}
            loading={loading}
          />
        </form>
      </DialogContent>
      <ToastContainer position="bottom_left" />
    </Dialog>
  );
};

export default FormSummary;
