import logo from "../images/logo.png"

const Loading = ({ height = "80px", width= "80px"}) => {
  return (
    <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <img
        alt="loading img"
        className="loading"
        src={logo}
        style={{width, height}}
      />
    </div> 
   );
}
 
export default Loading;