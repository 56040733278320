import { Box, Typography } from "@mui/material";
// import Manos from "../images/Manos.png";

const InfoNoglaxman = () => {
  return (
    <Box sx={{ mb: "3rem", mt: "5rem" }}>
      <Typography
        variant='h4'
        sx={{ fontWeight: 400, fontFamily: '"Rubik", sans-serif', fontSize: { xs:'30px', sm: "40px"}
      }}
      >
        Acerca de Noglaxman
      </Typography>
      <Typography variant="body1" align="center" sx={{ mt: "1rem", color: "#4F5665", fontFamily: '"Rubik", sans-serif' }}>
        Noglaxman es una pequeña empresa, conformada por un grupo de inversores con más de
        4 años en el mercado, que tiene como CEO a Ozán Gonzalo Agustín.
      </Typography>
    </Box>
  );
};

export default InfoNoglaxman;
