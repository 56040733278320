import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Typography, Divider, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Paper, TextField, Select, MenuItem, FormControl, InputLabel, CircularProgress, TablePagination, Tooltip, Zoom } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FormSummary from "../components/FormSummary";
import Swal from "sweetalert2";
import { API_NOGLAXMAN } from "../utils/config";
import { DatePicker } from "@mui/x-date-pickers";
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";
import { currencyFormatter } from "../utils/utils";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ViewerPdf from "../components/ViewerPdf"
import ButtonNoglax from "../components/ButtonNoglax";
import noDataImg from "../images/svg-person/no-data-user.svg"

const Summary = ({ accountId, setOpenAddSummary, openAddSummary, isEdit=true }) => {
  const [summary, setSummary] = useState([]);

  const token = window.localStorage.getItem('token');

  // const { accountId } = useParams();

  const [edit, setEdit] = useState(false);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [startDate, setStartDate] = useState('');

  const [endDate, setEndDate] = useState('');

  const [summaryType, setSummaryType] = useState('')

  const [loading, setLoading] = useState(false)

  const [openPDF, setOpenPDF] = useState(false)

  const [dataSelect, setDataSelect] = useState({})

  const [loadingSummary, setLoadingSummary] = useState(false)

  const [startDateEdit, setStartDateEdit] = useState("");
  const [endDateEdit, setEndDateEdit] = useState("");
  const [profit, setProfit] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [withdraw, setWithdraw] = useState("");
  const [summaryTypeEdit, setSummaryTypeEdit] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: 'summary_date_start', label: 'Fecha inicial', minWidth: 140, format: (value) => value.substring(0, 10) },
    { id: 'summary_date_end', label: 'Fecha final', minWidth: 140, format: (value) => value.substring(0, 10) },
    { id: 'type', label: 'Tipo de resumen', minWidth: 140, format: (value) => value === "long" ? "Largo" : "Corto" },
    { id: 'profit', label: 'Ganancia', minWidth: 150, format: (value) => currencyFormatter({ currency: "USD", value }) },
    { id: 'withdraw', label: 'Monto retirado', minWidth: 160, format: (value) => value ? currencyFormatter({ currency: "USD", value }) : "-" },
    { id: 'total_amount', label: 'Monto total', minWidth: 160, format: (value) => currencyFormatter({ currency: "USD", value }) },
    isEdit && { id: 'actions', label: 'Acciones', minWidth: 10 },
  ];

  const handleClose = () => {
    setOpenAddSummary(false);
  };

  const eliminarResumen = async (resumenId) => {
    try {
      const resolve = await fetch(`${API_NOGLAXMAN}/summary/${resumenId}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      });
      await resolve.json();
    } catch (error) {
      Swal.fire({
        text: `Ocurrió un error`,
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#1b9e17",
        background: "#eaeaea",
        backdrop: "rgba(0, 0, 0, 0.8)",
        reverseButtons: true
      })
    }
  };

  const searchSummary = async (accountId, clearFilter = false) => {
    setLoading(true)
    const dataAccount = {
      account_id: accountId
    };
    if (startDate && !clearFilter) {
      dataAccount.startDate = startDate;
    }
    if (endDate && !clearFilter) {
      dataAccount.endDate = endDate;
    }
    if (summaryType && !clearFilter) {
      dataAccount.summaryType = summaryType;
    }
    const resolve = await fetch(`${API_NOGLAXMAN}/summary/account`, {
      method: "POST",
      body: JSON.stringify(dataAccount),
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    });
    const data = await resolve.json();
    if (data) {
      data.sort((a, b) => new Date(b.summary_date_start) - new Date(a.summary_date_start));
      setSummary(data);
    }
    setLoading(false)
  };

  useEffect(() => {
    searchSummary(accountId);
    // eslint-disable-next-line
  }, [accountId]);

  const confirmDelete = async (summary_id, accountId) => {
    await eliminarResumen(summary_id);
    searchSummary(accountId);
  }

  const updateSummary = async () => {
    setLoadingSummary(true)
    const newSummary = {
      summary_date_start: startDateEdit,
      summary_date_end: endDateEdit,
      profit: profit,
      total_amount: totalAmount,
      withdraw: withdraw,
      type: summaryTypeEdit,
    }

    try {
      const resolve = await fetch(`${API_NOGLAXMAN}/summary/${edit}`, {
        method: 'PUT',
        body: JSON.stringify(newSummary),
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      });
      const data = await resolve.json();
      if (data.message) {
        Swal.fire({
          text: "Resumen actualizado con éxito!",
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: "#1b9e17",
          background: "#eaeaea",
          backdrop: "rgba(0, 0, 0, 0.8)"
        })
      }
      await searchSummary(accountId);
      setLoadingSummary(false)
    } catch (error) {
      setLoadingSummary(setLoadingSummary)
      Swal.fire({
        title: 'Error!',
        text: "No se pudo actualizar el resumen.",
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "95%", justifyContent: "center", alignItems: "center"  }}>
      <div style={{ display: "flex", width: "100%", paddingBottom: "10px", justifyContent: "start", alignItems: "center", gap: 20, flexWrap:"wrap"}}>
        <div style={{display:"flex", gap: 20}}>
          <DatePicker
            label="Desde"
            format="YYYY/MM/DD"
            slotProps={{ textField: { size: 'small', color: "primary" } }}
            value={startDate ? moment(startDate, 'YYYY-MM-DD') : null}
            onChange={(value) => { setStartDate(value ? value.format('YYYY-MM-DD') : null) }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
          <DatePicker
            label="Hasta"
            format="YYYY/MM/DD"
            slotProps={{ textField: { size: 'small', color: "primary" } }}
            value={endDate ? moment(endDate, 'YYYY-MM-DD') : null}
            onChange={(value) => { setEndDate(value ? value.format('YYYY-MM-DD') : null) }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </div>
        <FormControl sx={{width: {xs:"150px", md:"20%"} }}>
          <InputLabel id="type-summary-label" color="primary" size="small">Tipo de resumen</InputLabel>
          <Select
            labelId="type-summary-label"
            id="type-summary"
            label="Tipo de resumen"
            size="small"
            color="primary"
            value={summaryType}
            onChange={(e) => setSummaryType(e.target.value)}
          >
            <MenuItem value={"short"}>Corto</MenuItem>
            <MenuItem value={"long"}>Largo</MenuItem>
          </Select>
        </FormControl>
        <ButtonNoglax
          type="button"
          variant="contained"
          size="medium"
          onClick={() => {
            searchSummary(accountId)
          }}
          loading={loading}
          title={<SearchIcon />}
          titleTooltip={"Buscar"}
          tooltip
        />
        <Tooltip title="Limpiar filtros" arrow TransitionComponent={Zoom} placement="top">
          <Button
            type="button"
            variant="contained"
            size="medium"
            color="warning"
            sx={{}}
            aria-label="search"
            onClick={() => {
              setSummaryType("")
              setEndDate("")
              setStartDate("")
              searchSummary(accountId, true)
            }}
          >
            <ClearIcon size={24} sx={{ color: "#fff" }} />
          </Button>
        </Tooltip>
      </div>
      <Paper sx={{ height: "80%", width: "100%", zIndex: 1000, display: "flex", flexDirection: "column" }}>
        <TableContainer sx={{ height: "100%", zIndex: 1000 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              position: (!loadingSummary && !summary.length) && "relative",
              width: "100%",
              "& .MuiTableRow-root:hover": {
                backgroundColor: "rgba(0,0,0,0.014)"
              }
            }}
          >
            <TableHead elevation={8}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{ backgroundColor: "#eeeeee" }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography variant={"subtitle1"} sx={{}} >{column.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!loadingSummary && summary && !summary.length ? (
              <div style={{ position: "absolute", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={noDataImg} alt="No hay información" style={{ width: "300px", height: "300px" }} />
              </div>
            ) : null}
            <TableBody>
              {!loadingSummary && summary && summary
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((sum, index) => {
                  return (

                    <TableRow hover role="checkbox" tabIndex={-1} key={sum.code} onClick={() => {
                      if (edit) {
                        return null;
                      }
                      setOpenPDF(true)
                      setDataSelect({ url: sum.summary_url, summary_date_start: sum.summary_date_start, summary_date_end: sum.summary_date_end })
                    }}>
                      {columns.map((column) => {
                        const value = sum[column.id];
                        return (
                          <TableCell
                            key={column.id} align={column.align}
                            sx={{ cursor: "pointer" }}
                          // onClick={async () => {
                          //   await getClient(cliente.client_id);
                          //   handleClickOpen();
                          //   setUpdateClient(true);
                          // }}
                          >
                            {sum.summary_id === edit ? (
                              <>
                                {column.id === "summary_date_start" &&
                                  <DatePicker
                                    sx={{ width: "100%" }}
                                    size="small"
                                    label="Fecha inicial"
                                    format="YYYY/MM/DD"
                                    slotProps={{ textField: { size: 'small', color: "primary" } }}
                                    value={startDateEdit ? moment(startDateEdit, 'YYYY-MM-DD') : null}
                                    onChange={(value) => { setStartDateEdit(value ? value.format('YYYY-MM-DD') : null) }}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                  />}
                                {column.id === "summary_date_end" && (<DatePicker
                                  sx={{ width: "100%" }}
                                  label="Fecha final"
                                  format="YYYY/MM/DD"
                                  slotProps={{ textField: { size: 'small', color: "primary" } }}
                                  value={endDateEdit ? moment(endDateEdit, 'YYYY-MM-DD') : null}
                                  onChange={(value) => { setEndDateEdit(value ? value.format('YYYY-MM-DD') : null) }}
                                  renderInput={(params) => <TextField size="small" {...params} />}
                                />)}
                                {column.id === "type" && (<FormControl sx={{ width: "100%", zIndex: 2000 }}>
                                  <InputLabel id="type-summary-label" color="primary" size="small">Tipo de resumen</InputLabel>
                                  <Select
                                    labelId="type-summary-label"
                                    id="type-summary"
                                    label="Tipo de resumen"
                                    size="small"
                                    color="primary"
                                    value={summaryTypeEdit}
                                    onChange={(e) => setSummaryTypeEdit(e.target.value)}
                                  >
                                    <MenuItem value={"short"}>Corto</MenuItem>
                                    <MenuItem value={"long"}>Largo</MenuItem>
                                  </Select>
                                </FormControl>)}
                                {column.id === "profit" && (<TextField
                                  sx={{ width: "100%" }}
                                  size="small"
                                  color="primary"
                                  type="number"
                                  id="outlined-required"
                                  label="Profit"
                                  name="profit"
                                  value={profit}
                                  onChange={(e) => setProfit(e.target.value ? e.target.value : null )}
                                />)}
                                {column.id === "withdraw" && (<TextField
                                  sx={{ width: "100%" }}
                                  size="small"
                                  color="primary"
                                  type="number"
                                  id="outlined-required"
                                  label="Monto retirado"
                                  name="withdraw"
                                  value={withdraw}
                                  onChange={(e) => setWithdraw(e.target.value ? e.target.value : "0" )}
                                />)}
                                {column.id === "total_amount" && (<TextField
                                  size="small"
                                  color="primary"
                                  sx={{ width: "100%" }}
                                  type="number"
                                  id="outlined-required"
                                  label="Monto total"
                                  name="ammount_total"
                                  value={totalAmount}
                                  onChange={(e) => setTotalAmount(e.target.value ? e.target.value : null )}
                                />)}
                                {column.id === "actions" && (
                                  <div style={{ display: "flex", gap: 1, flexWrap: "nowrap" }}>
                                    <IconButton
                                      type="button"
                                      sx={{ p: '10px' }}
                                      onClick={async (e) => {
                                        e.stopPropagation();
                                        if (!loadingSummary) {
                                          await updateSummary()
                                          setEdit(null)
                                          setStartDateEdit("")
                                          setEndDateEdit("")
                                          setProfit(null)
                                          setTotalAmount(null)
                                          setWithdraw(null)
                                          setSummaryTypeEdit("")
                                        }
                                      }}
                                    >
                                      {loadingSummary ? (
                                        <CircularProgress color="primary" size={24} />
                                      ) : (
                                        <CheckIcon
                                          color="success"
                                        />
                                      )}

                                    </IconButton>
                                    <IconButton
                                      type="button"
                                      sx={{ p: '10px' }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setEdit(null)
                                        setStartDateEdit("")
                                        setEndDateEdit("")
                                        setProfit(null)
                                        setTotalAmount(null)
                                        setWithdraw(null)
                                        setSummaryTypeEdit("")
                                      }}
                                    >
                                      <ClearIcon
                                        color="error"
                                      />
                                    </IconButton>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div style={{ display: "flex" }}>
                                {column.format && (typeof value === 'number' || typeof value === 'string' || value === null)
                                  ? <Typography variant="body1" sx={{ marginLeft: "5px" }}>{column.format(value)}</Typography>
                                  : <Typography variant="body1" sx={{ marginLeft: "5px" }}>{value}</Typography>}
                                {column.id === "actions" && (
                                  <>
                                    <IconButton
                                      type="button"
                                      sx={{ p: '10px' }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setEdit(sum.summary_id)
                                        setStartDateEdit(sum.summary_date_start)
                                        setEndDateEdit(sum.summary_date_end)
                                        setProfit(sum.profit)
                                        setTotalAmount(sum.total_amount)
                                        setWithdraw(sum.withdraw)
                                        setSummaryTypeEdit(sum.type)
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton type="button" sx={{ marginLeft: "5px", p: '10px' }}>
                                      <DeleteIcon
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          Swal.fire({
                                            text: `¿Desea eliminar el resumen?`,
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonText: "Aceptar",
                                            cancelButtonText: "Cancelar",
                                            cancelButtonColor: "#aB0e0e",
                                            confirmButtonColor: "#1b9e17",
                                            background: "#eaeaea",
                                            backdrop: "rgba(0, 0, 0, 0.8)",
                                            reverseButtons: true
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              confirmDelete(sum.summary_id, accountId);
                                              Swal.fire({
                                                text: "Cliente eliminado con éxito!",
                                                icon: "success",
                                                confirmButtonText: "Ok",
                                                confirmButtonColor: "#1b9e17",
                                                background: "#eaeaea",
                                                backdrop: "rgba(0, 0, 0, 0.8)",
                                              });
                                            }
                                          });
                                        }}
                                      />
                                    </IconButton>
                                  </>
                                )}
                              </div>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          summary && summary.length > 10 &&
          (<>
            <Divider></Divider>
            <TablePagination
              sx={{ overflow: "hidden" }}
              labelRowsPerPage="Filas por página:"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={summary && summary.length ? summary.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>)
        }
      </Paper>
      <FormSummary
        searchSummary={searchSummary}
        open={openAddSummary}
        handleClose={handleClose}
        accountId={accountId}
      />
      <ViewerPdf
        open={openPDF}
        fileURL={dataSelect && dataSelect.url}
        setOpen={setOpenPDF}
        setDataSelect={setDataSelect}
        title={`Desde ${dataSelect && dataSelect.summary_date_start && dataSelect.summary_date_end ? `${dataSelect.summary_date_start.substring(0, 10)} hasta ${dataSelect.summary_date_end.substring(0, 10)}` : ""}`}
      />
    </div>
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    // >
    //   <div style={{ width: "80%" }}>
    //     <Box
    //       sx={{
    //         marginTop: "32px",
    //         display: "flex",
    //         flexDirection: {xs: "column", md: "row"},
    //         justifyContent: "space-between",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Typography
    //         sx={{
    //           textAlign:{ xs: "center", md: "start"},
    //           width: {xs: "100%", md: "50%"},
    //           fontWeight: "bold",
    //           fontSize: {xs: "16px", sm: "18px", md: "20px", lg: "20px"},
    //         }}>
    //         Resúmenes de la cuenta {accountId}
    //       </Typography>
    //       <Button
    //         onClick={() => {
    //           handleClickOpen();
    //         }}
    //         color="primary"
    //         variant="outlined"
    //         sx={{
    //           width: {xs: "100%", sm:'80%', md: "30%", lg: "25%"},
    //           fontSize: {xs: "10px", sm: "12px", md: "14px", lg: "16px"},
    //           border: "1px solid #9565a7",
    //           fontWeight: "bold"
    //         }}
    //       >
    //         Agregar resumen
    //       </Button>
    //     </Box>
    //     <div
    //       style={{ marginTop: "16px", maxHeight: "70vh", overflowY: "scroll" }}
    //     >
    //       {summary ? (
    //         summary.map((sum) => {
    //           return (
    //             <List
    //               key={sum.summary_id}
    //               sx={{ width: "100%", backgroundColor: "#efefef" }}
    //               component="nav"
    //               aria-label="mailbox folders"
    //             >
    //               <ListItemButton>
    //                 <a
    //                   href={sum.summary_url}
    //                   target="_ blank"
    //                   rel="noreferrer"
    //                   style={{
    //                     width: "100%",
    //                     color: "#000",
    //                     textDecoration: "none",
    //                   }}
    //                 >
    //                   <div style={{ width: "100%" }}>
    //                     <Typography
    //                       sx={{
    //                         display: "inline",
    //                         fontSize: {xs: "12px", sm: "14px", md: "16px", lg: "18px"},
    //                         }}>
    //                       Resumen del{" "}
    //                     </Typography>
    //                     <Typography
    //                       sx={{
    //                         fontWeight: "bold",
    //                         display: "inline",
    //                         fontSize: {xs: "12px", sm: "14px", md: "16px", lg: "18px"},
    //                       }}
    //                     >
    //                       {sum.summary_date.substring(0, 10)}
    //                     </Typography>
    //                   </div>
    //                 </a>
    //                 <DeleteIcon
    //                   sx={{
    //                     fontSize: {xs: "18px", sm: "20px", md: "22px", lg: "24px"},
    //                     color: "#000",
    //                     transition: "0.1s",
    //                     ":hover": {
    //                       color: "#d32f2f",
    //                       transform: "scale(1.15)",
    //                     }
    //                   }}
    //                   onClick={() => deleteSummary(sum.summary_date, sum.summary_id)}
    //                 />
    //               </ListItemButton>
    //               <Divider />
    //             </List>
    //           );
    //         })
    //       ) : (
    //         <>Cargando...</>
    //       )}
    //     </div>
    //   </div>
    // </div>
  );
};

export default Summary;
