import { Button, CircularProgress, Tooltip, Zoom } from "@mui/material";

const ButtonNoglax = ({
  variant,
  title,
  StartIcon,
  size,
  onClick,
  type = "button",
  loading = false,
  style = {},
  tooltip = false,
  titleTooltip
}) => {

  const styles =
    variant === "contained" ? {
      backgroundColor: "rgb(164, 33, 230)",
      color: "#fff",
      '&:hover': {
        backgroundColor: 'rgb(164, 33, 240)'
      }
    } : variant === "outlined" ?
      {
        color: 'rgb(164, 33, 240)',
        border: "1px solid rgb(164, 33, 230)",
        marginRight: "20px",
        '& .MuiButton-root.MuiButton-startIcon': {
          color: 'rgb(164, 33, 240)'
        },
        '&:hover': {
          backgroundColor: 'rgb(164, 33, 240, .05)'
        },
      } : variant === "" ? {
        color: 'rgb(164, 33, 240)',
        marginRight: "20px",
        '& .MuiButton-root.MuiButton-startIcon': {
          color: 'rgb(164, 33, 240)'
        },
        '&:hover': {
          backgroundColor: 'rgb(164, 33, 240, .05)'
        },
      } : {}


  return (
    tooltip ? (
      <Tooltip title={titleTooltip} arrow TransitionComponent={Zoom} placement="top">
        <Button
          startIcon={StartIcon && <StartIcon />}
          size={size}
          // variant={variant}
          type={type}
          sx={{ ...style, ...styles }
          }
          onClick={onClick}
        >
          {loading ? <CircularProgress size={24} sx={{ color: variant === "contained" ? '#fff' : "rgb(164, 33, 240)" }} /> : title}
        </Button >
      </Tooltip>
    ) :
      (
        <Button
          startIcon={StartIcon && <StartIcon />}
          size={size}
          // variant={variant}
          type={type}
          sx={{ ...style, ...styles }}
          onClick={onClick}
        >
          {loading ? <CircularProgress size={24} sx={{ color: variant === "contained" ? '#fff' : "rgb(164, 33, 240)" }} /> : title}
        </Button>
      )

  );
}

export default ButtonNoglax;