import { useEffect, useState } from "react";
import { API_NOGLAXMAN } from "../utils/config";
import GraphicsCount from "../components/GraphicsCount";
import { Grid } from "@mui/material";
import userImage from "../images/svg-person/User.svg"
import userAccount from "../images/svg-person/user_account.svg"
import userCash from "../images/svg-person/user_cash.svg"
import userAverage from "../images/svg-person/average_user.svg"
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import { currencyFormatter } from "../utils/utils";

const DashboardAdmin = () => {

  const [amountClient, setAmountClient] = useState(0)
  const [amountAccounts, setAmountAccounts] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [averageAccountOfClient, setAvergeAccountOfClient] = useState(0)
  const [loading, setLoading] = useState(false)

  const token = window.localStorage.getItem('token');

  const getGraphicsCountClients = async () => {
    setLoading(true)
    const result = await fetch(`${API_NOGLAXMAN}/getGraphicsCountClients`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }})
    const resolve = await result.json()
    if (resolve && resolve.length){
      setAmountClient(resolve[0].quantity)
    }
    setLoading(false)
  }

  const getGraphicsCountAccounts = async () => {
    setLoading(true)
    const result = await fetch(`${API_NOGLAXMAN}/getGraphicsCountAccounts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    const resolve = await result.json()
    if (resolve && resolve.length) {
      setAmountAccounts(resolve[0].quantity)
    }
    setLoading(false)
  }

  const getGraphicsAllMount = async () => {
    setLoading(true)
    const result = await fetch(`${API_NOGLAXMAN}/getGraphicsAllMount`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    const resolve = await result.json()
    if (resolve && resolve.length) {
      const sumaTotalAmount = resolve.reduce((acumulador, object) => {
        const total = (Math.floor(object.total_amount * 100) / 100)
        return acumulador + total
      }, 0);
      setTotalAmount(sumaTotalAmount)
    }
    setLoading(false)
  }

  const getGraphicsAverageAccountByClient = async () => {
    setLoading(true)
    const result = await fetch(`${API_NOGLAXMAN}/getGraphicsAverageAccountByClient`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    const resolve = await result.json()
    if (resolve && resolve.length) {
      setAvergeAccountOfClient(`${resolve[0].account_of_client_average}%`)
    }
    setLoading(false)
  }

  useEffect(() => {
    getGraphicsCountClients()
    getGraphicsCountAccounts()
    getGraphicsAllMount()
    getGraphicsAverageAccountByClient()
  },[])
  return ( 
    <div style={{ width: "100%", height: "calc(100vh - 64px)", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f7f6f9"}}>
      <div style={{ width: "80%" }}>
        <Grid container md={12} justifyContent={"center"} alignItems={"center"} rowSpacing={6}>
          <Grid item md={6} sx={{display: "flex", justifyContent: "center"}}>
            <GraphicsCount amount={amountClient} title={"Usuarios"} Icon={PersonOutlineOutlinedIcon} img={userImage} iconColor={"rgb(196, 141, 216)"} iconBackground={"rgb(196, 141, 216, 0.15)"} width="50%" loading={loading}/>
          </Grid>
          <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
            <GraphicsCount amount={currencyFormatter({ currency: "USD", value: totalAmount })} title={"Capital gestionado"} Icon={AttachMoneyIcon} img={userCash} iconColor={"rgb(139, 210, 146)"} iconBackground={"rgba(139, 210, 146, 0.15)"} width="35%" loading={loading} />
          </Grid>
          <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
            <GraphicsCount amount={amountAccounts} title={"Cuentas"} Icon={BadgeOutlinedIcon} img={userAccount} iconColor={"rgb(79, 168, 251)"} iconBackground={"rgb(79, 168, 251, 0.15)"} width="50%" loading={loading} />
          </Grid>
          <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
            <GraphicsCount amount={averageAccountOfClient} title={"Promedio de"} title2={"cuentas por usuario"} Icon={PercentOutlinedIcon} img={userAverage} iconColor={"rgb(255, 225, 128)"} iconBackground={"rgba(255, 225, 128, 0.20)"} width="35%" loading={loading} />
          </Grid>
        </Grid>
      </div>
    </div>
   );
}
 
export default DashboardAdmin;