import Nosotros from "../pages/Nosotros";
import InfoCeo from "../pages/InfoCeo";
import Contacto from "../pages/Contacto";
import HomeV2 from "../pages/HomeV2";
import Footer from "../components/Footer";
import Empresas from "../pages/Empresas";
import { Element } from "react-scroll";

const AuthLayout = () => {

  window.localStorage.removeItem("token");

  return ( 
    <div className="App">
      <HomeV2 />
      <Element name="about">
        <Nosotros />
      </Element>
      <Element name="we">
        <InfoCeo />
      </Element>
      <Element name="contact">
        <Contacto />
      </Element>
      <Empresas />
      <Footer />
    </div>
   );
}
 
export default AuthLayout;