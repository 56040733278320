import { Box, Typography } from "@mui/material";
import Carrousel from "../components/Carrousel";
import ceo from "../images/persons/ceo.jpeg"
import operatorPablo from "../images/persons/pablojpeg.jpeg"
import operatorCristian from "../images/persons/operator.jpeg"
import operatorSanti from "../images/persons/santi.jpeg"

const InfoCeo = () => {

  const data = [
    {
      title: "CEO Y FUNDADOR DE “NOGLAXMAN TRADING INVESTMENTS”",
      name: "Ozán, Gonzalo Agustin",
      skills: [
        "Gestor y Director de Fondos de inversiones en Argentina , trader con más de 4 años de experiencia en inversiones bursátiles.",
        "Recibido de Analista de Finanzas corporativas y personales, (ICB Arg.)",
        "Agente de gestor de bolsa Arg titulado (CNV).",
        "Fundador de NOGLAXMAN IT, dedicado al fondo de inversiones más rentable en Argentina sobre trading y diversos mercados internacionales."
        ],
      img: ceo
    },
    {
      title: "GESTOR Y OPERADOR",
      name: "Domínguez, Pablo Ramón",
      skills: [
        "Trader e Operador bursátil ,especialista en análisis técnico sobre Scalping, experimentado en mercado Forex e Commodities."
      ],
      img: operatorPablo
    },
    {
      title: "GESTOR Y OPERADOR",
      name: "Carro, Cristian Martín",
      skills: [
        "Trader e Operador bursátil de mediano y largo plazo, analista fundamental experimentado en mercado Merval Ars , especializado en Exchanges y wallets."
      ],
      img: operatorCristian
    },
    {
      title: "ANALISTA SENIOR",
      name: "Benítez Hilt Santiago Román",
      skills: [
        "Analista bursátil , especializado en Gestiones de riesgo básicas, generales y avanzadas.",
        "Administrador y Data Analytics de cuentas."
      ],
      img: operatorSanti
    }
  ]

  return (
    <Box
      id="we"
      style={{
        backgroundColor: "#fafafa",
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden",
        alignItems:"center"
      }}
    >
      <Typography
        variant='h4'
        sx={{
          fontWeight: 400, fontFamily: '"Rubik", sans-serif', fontSize: { xs: '30px', sm: "40px" },
        }}
      >
        ¿Quienes somos?
      </Typography>
      <div style={{width:"80%", overflow: "hidden"}}>
        <Carrousel data={data}/>
      </div>
      {/* <Typography
        variant="h4"
        align='center'
        sx={{
          width: '80%',
          color: "#fff",
          fontWeight: "bold",
          fontFamily: "'Proza Libre', sans-serif",
          letterSpacing: 2.5,
          mt: "5rem",
          mb: "5rem",
          fontSize: { xs:'20px', sm: "28px", md: '40px'}
        }}
      >
        CEO Y FUNDADOR DE NOGLAXMAN IT
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection:{xs:'column',sm:'column', md:'row'},
          bgcolor: "rgba(250,250,250,0.08)",
          boxShadow: "0 0 2px #999",
          padding: {xs:'1rem', md:"2rem"},
          maxWidth: "80%",
          mb:{xs:'40px', md:0}
        }}
      >
        <CardMedia
          sx={{ width: {xs:'%60', md:"30%"}, bgcolor: "drakgreen", borderRadius: "50%", boxShadow:'0 0 2px #000' }}
          component="img"
          image={imagenCeo}
        />
        <Box
          sx={{
            padding: "1rem",
            width: {xs:'100%', md:"70%"},
            height: "100%",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            ml:{md:'24px'}
          }}
        >
          <Typography variant="h4" sx={{ color: "#fff", fontFamily: "Montserrat", fontWeight: "bold", fontSize: { xs:'24px', sm: "28px", md: '30px'} }}>
            Ozán Gonzalo Agustín
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#fff", width: "100%", fontFamily: "Montserrat", fontSize: { xs:'18px', sm: "20px", md: '24px'} }}
          >
            Trader e inversor con más de 3 años de experiencia (2018 - 2022)
            en los mercados financieros
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#fff", width: "100%", fontFamily: "Montserrat",fontSize: { xs:'18px', sm: "20px", md: '24px'} }}
          >
            Educado financieramente por ICB (Instituto de Capacitaciones
            Bursátiles - Buenos Aires)
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#fff", width: "100%", fontFamily: "Montserrat",fontSize: { xs:'18px', sm: "20px", md: '24px'} }}
          >
            Título certificado de AGI (Inversor y Asesor Global de
            inversiones).
          </Typography>
        </Box>
      </Box> */}
    </Box>
  );
};

export default InfoCeo;
