import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  InputBase,
  IconButton,
  Divider,
  Slide,
  CircularProgress,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { API_NOGLAXMAN } from "../utils/config.js";
import AddIcon from '@mui/icons-material/Add';
import TabsComponent from "../components/TabsComponent.jsx";
import AvatarComponent from "../components/AvatarComponent.jsx";
import ButtonNoglax from "../components/ButtonNoglax.jsx";
import noDataImg from "../images/svg-person/no-data-user.svg"

const Admin = () => {
  const [clientes, setClientes] = useState("");
  const [open, setOpen] = useState(false);
  const [client, setClient] = useState([]);
  const [updateClient, setUpdateClient] = useState(false);
  const [searchInput, setSearchInput] = useState('')
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (window.location.pathname === '/admin/dashboard') {
      window.onpopstate = e => {
        window.localStorage.removeItem('token')
      };
    }
  })

  const token = window.localStorage.getItem("token");

  const getAllClient = async (where) => {
    setLoading(true)
    const url = `${API_NOGLAXMAN}/client?where=${where ? where : ""}`
    const resolve = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await resolve.json();
    const dataOrder = data.sort(function (a, b) {
      if (a.lastname > b.lastname) {
        return 1;
      }
      if (a.lastname < b.lastname) {
        return -1;
      }
      return 0;
    });
    if (data) {
      setClientes(dataOrder);
    }
    setLoading(false)
  };

  const getClient = async (id) => {
    const resolve = await fetch(`${API_NOGLAXMAN}/client/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await resolve.json();
    if (data) {
      setClient(data[0]);
    } else {
      console.log("error");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAllClient(searchInput);
    setPage(0)
    // eslint-disable-next-line
  }, [searchInput]);

  const deleteClient = async (id) => {
    const resolve = await fetch(`${API_NOGLAXMAN}/client/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    await resolve.json();
  };

  const confirmDelete = async (id) => {
    await deleteClient(id);
    getAllClient();
  };

  const columns = [
    { id: 'avatar', label: '', maxWidth: 10 },
    { id: 'dni', label: 'Dni', minWidth: 150 },
    { id: 'lastname', label: 'Apellido', minWidth: 200 },
    { id: 'name', label: 'Nombre', minWidth: 200 },
    { id: 'username', label: 'Usuario', minWidth: 200 },
    { id: 'num_accounts', label: 'Núm. cuentas', minWidth: 150 },
    { id: 'actions', label: 'Acciones', minWidth: 30 },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fefefe",
        height: "calc(100vh - 64px)",
        flexDirection: "column"
      }}
    >
      <Paper sx={{ width: '90%', overflow: 'hidden', height: "90%", position: "relative" }}>
        <Slide direction="left" in={open} mountOnEnter unmountOnExit sx={{ position: "absolute", height: "100%", width: "100%", zIndex: 1000 }}>
          <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
            {/* <FormCliente updateClient={updateClient} client={client} handleClose={handleClose} getAllClient={getAllClient} setOpen={setOpen} /> */}
            <TabsComponent updateClient={updateClient} client={client} handleClose={handleClose} getAllClient={getAllClient} setOpen={setOpen} />
          </Paper>
        </Slide>
        <div style={{ height: "calc(100% - 56px)", overflowY: "auto" }}>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", height: "70px" }}>
            <div
              component="form"
              style={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, border: "2px solid #eeeeee", borderRadius: "5px", margin: "10px 20px" }}
            >
              <IconButton
                type="button" sx={{ p: '10px' }}
                aria-label="search"
                onClick={() => {
                  if (!loading) {
                    if (searchInput) {
                      getAllClient(searchInput)
                    }
                    else {
                      getAllClient("")
                    }
                  }
                }}
              >
                {loading ?
                  <CircularProgress size={24} sx={{ color: "#767676" }} /> :
                  <SearchIcon />
                }
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Buscar en clientes"
                inputProps={{ 'aria-label': 'Buscar en clientes' }}
                onChange={(e) => {
                  setSearchInput(e.target.value)
                }}
              />
            </div>
            <ButtonNoglax 
              title={"Agregar cliente"}
              variant=""
              StartIcon={AddIcon}
              size={"medium"}
              onClick={() => {
                handleClickOpen();
                setUpdateClient(false);
              }}
            />
          </div>
          <TableContainer sx={{ height: "calc(100% - 70px)", zIndex: 1000}}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                position: (!loading && !client.length) && "relative",
                width: "100%",
                "& .MuiTableRow-root:hover": {
                  backgroundColor: "rgba(0,0,0,0.014)"
                }
              }}
            >
              <TableHead elevation={8}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ backgroundColor: "#eeeeee" }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography variant={"subtitle1"} sx={{}} >{column.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {!loading && !clientes.length ? (

                <div style={{ position: "absolute", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img src={noDataImg} style={{ width: "300px", height: "300px" }} alt="No hay información" />
                </div>
              ) :
                null}
              <TableBody>
                {!loading && clientes && clientes
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((cliente, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={clientes.code}>
                        {columns.map((column) => {
                          const value = cliente[column.id];
                          return (
                            <TableCell
                              key={column.id} align={column.align}
                              sx={{ cursor: "pointer" }}
                              onClick={async () => {
                                await getClient(cliente.client_id);
                                handleClickOpen();
                                setUpdateClient(true);
                              }}
                            >
                              {column.id === "avatar" && (
                                <AvatarComponent string={`${cliente.lastname} ${cliente.name}`} height={"30px"} width={"30px"} fontSize={"14px"} />
                              )}
                              {column.format && typeof value === 'number'
                                ? <Typography variant="body1" sx={{ marginLeft: "5px" }}>{column.format(value)}</Typography>
                                : <Typography variant="body1" sx={{ marginLeft: "5px" }}>{value}</Typography>}
                              {column.id === "actions" && (
                                <IconButton type="button" sx={{ marginLeft: "5px", p: '10px' }}>
                                  <DeleteIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      Swal.fire({
                                        text: `¿Desea eliminar este cliente?`,
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: "Aceptar",
                                        cancelButtonText: "Cancelar",
                                        cancelButtonColor: "#aB0e0e",
                                        confirmButtonColor: "#1b9e17",
                                        background: "#eaeaea",
                                        backdrop: "rgba(0, 0, 0, 0.8)",
                                        reverseButtons: true
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          confirmDelete(cliente.client_id);
                                          Swal.fire({
                                            text: "Cliente eliminado con éxito!",
                                            icon: "success",
                                            confirmButtonText: "Ok",
                                            confirmButtonColor: "#1b9e17",
                                            background: "#eaeaea",
                                            backdrop: "rgba(0, 0, 0, 0.8)",
                                          });
                                        }
                                      });
                                    }}
                                  />
                                </IconButton>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Divider />
      <TablePagination
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={clientes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Paper>
    </div>

  );
};

export default Admin;
