import {
  AppBar,
  styled,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Avatar,
  MenuItem,
  ListItemIcon,
  Menu,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Draw from "./Draw";
import FormLogin from "./FormLogin.jsx";
import Swal from "sweetalert2";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "react-scroll";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const Nav = () => {

  useEffect(() => {
    if (window.location.pathname === "/admin/dashboard" || window.location.pathname === "/client/dashboard"){
      setValue(0)
    }
    else if(window.location.pathname === "/admin/clients" || window.location.pathname === "/client/accounts"){
      setValue(1)
    }
  },[window.location.pathname])
  
  const [value, setValue] = useState(null);

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const path = window.location.pathname;

  const name = window.localStorage.getItem("name")
  const lastname = window.localStorage.getItem("lastname")

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  const logOut = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("client_id");
    window.localStorage.removeItem("name");
    window.localStorage.removeItem("lastname");
    navigate("/");
  };

  const handleClickOpen = () => {
    navigate("/login")
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (window.location.pathname === "/login"){
    return null;
  }
  
  return (
    <div>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{ background: "#fff", display: "flex", alignItems: "center", justifyContent:"space-between", boxShadow:"0px 0px 10px 0px rgba(0, 0, 0, 0.08)", zIndex: 1050 }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: window.location.pathname !== "/" ? "90%" : "80%",
            padding: 0,
          }}
        >
          <Grid
            container
            md={12}
            justifyContent={"space-between"}
            // sx={{display:"flex", justifyContent: "space-between"}}
          >
            <Grid 
              item
              lg={4}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
              >
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "28px", lg: "36px" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".10rem",
                  color: "rgb(164, 33, 230)",
                  textDecoration: "none",
                }}
              >
                NOGLAXMAN
              </Typography>
            </Grid>
            <Grid 
              item
              lg={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Toolbar>
                {path !== "/" ? (
                  <Tabs
                    sx={{ 
                      display: {
                        xs: "none", lg: "flex",
                        letterSpacing: 1, },
                      "& .MuiTab-root.Mui-selected": {
                        color: "rgb(164, 33, 230)",
                        fontFamily: "'Poppins', sans-serif",
                      },
                      "& .MuiTab-root": {
                        fontFamily: "'Poppins', sans-serif",
                      }
                    }}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "rgb(164, 33, 230)",
                        color: "rgb(164, 33, 230)",
                      },
                    }}
                    onChange={(e, value) => {
                      setValue(value);
                    }}
                    value={value === undefined ? 0 : value}
                    aria-label="simple tabs example"
                  >
                    <Tab
                      sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px", lg: "16px" } }}
                      style={{ fontWeight: "bold" }}
                      label="Dashboard"
                      onClick={() => {
                        if (window.location.pathname === "/client/dashboard" || window.location.pathname === "/client/accounts"){
                          navigate("/client/dashboard")
                        }
                        else{
                          navigate("/admin/dashboard")
                        }
                      }}
                    />
                    <Tab
                      sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px", lg: "16px" } }}
                      style={{ fontWeight: "bold" }}
                      label={window.location.pathname === "/admin/dashboard" || window.location.pathname === "/admin/clients" ? "Clientes" : window.location.pathname === "/client/accounts" || window.location.pathname === "/client/dashboard" ? "Cuentas" : ""}
                      onClick={() => {
                        if (window.location.pathname === "/client/dashboard" || window.location.pathname === "/client/accounts") {
                          navigate("/client/accounts")
                        }
                        else {
                          navigate("/admin/clients")
                        }
                      }}
                    />
                  </Tabs>
                ) : (
                  <Tabs
                      sx={{
                        display: {
                          xs: "none", md: "none", lg: "flex",
                          letterSpacing: 1,
                        },
                        "& .MuiTab-root.Mui-selected": {
                          color: "rgb(164, 33, 230)",
                          fontFamily: "'Poppins', sans-serif",
                        },
                        "& .MuiTab-root": {
                          fontFamily: "'Poppins', sans-serif",
                        }
                      }}
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "rgb(164, 33, 230)",
                          color: "rgb(164, 33, 230)"
                        },
                      }}
                    textcolor="primary"
                    // onChange={(e, value) => {
                    //   setValue(value);
                    // }}
                    // value={value === undefined ? 0 : value}
                  >
                  <Link
                    to="about" 
                    spy={true} 
                    smooth={true}
                    duration={250}
                  >
                    <Tab
                      sx={{fontSize: {xs: "10px", sm: "12px", md: "12px", lg: "16px"}, color:"rgba(0, 0, 0, 0.87)", textWrap: "noWrap"}}
                      style={{ fontWeight: "bold" }}
                      label="Acerca de"
                      />
                  </Link>
                  <Link
                    to="we" 
                    spy={true} 
                    smooth={true}
                    duration={250}
                    offset={-70}
                  >
                    <Tab
                      sx={{fontSize: {xs: "10px", sm: "12px", md: "12px", lg: "16px"}, color:"rgba(0, 0, 0, 0.87)", textWrap: "noWrap"}}
                      style={{ fontWeight: "bold" }}
                      label="Nosotros"
                      />
                  </Link>
                  <Link
                    to="contact" 
                    spy={true} 
                    smooth={true}
                    duration={250}
                  >
                    <Tab
                      sx={{fontSize: {xs: "10px", sm: "12px", md: "12px", lg: "16px"}, color:"rgba(0, 0, 0, 0.87)", textWrap: "noWrap"}}
                      style={{ fontWeight: "bold" }}
                      label="Contacto"
                      />
                  </Link>
                  </Tabs>
                )}
              </Toolbar>
            </Grid>
            <Grid
              item
              lg={4}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {path !== "/" ? (
                <>
                <Tooltip title="Opciones">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2, display: {xs: "none", lg: "flex"} }}
                    aria-controls={openMenu ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                  >
                      <Avatar sx={{ width: 32, height: 32, backgroundColor: "rgb(164, 33, 230)", padding: "2px", fontSize: "16px" }}>{name[0]}{lastname[0]}</Avatar>
                  </IconButton>
                </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openMenu}
                    onClose={handleCloseMenu}
                    onClick={handleCloseMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&::before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem 
                      onClick={() => {
                        Swal.fire({
                          text: "¿Desea cerrar sesión?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Aceptar",
                          cancelButtonText: "Cancelar",
                          cancelButtonColor: "#aB0e0e",
                          confirmButtonColor: "#1b9e17",
                          background: "#eaeaea",
                          backdrop: "rgba(0, 0, 0, 0.8)",
                          reverseButtons: true,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            logOut();
                          }
                        });
                      }}
                    >
                      <ListItemIcon
                      >
                        <LogoutIcon fontSize="small" />
                      </ListItemIcon>
                      Cerrar sesión
                    </MenuItem>
                  </Menu>
                  <Draw handleClickOpen={handleClickOpen} logOut={logOut}/>
              </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "rgb(164, 33, 230)",
                      height: "60%",
                      fontSize: { xs: "10px", sm: "12px", md: "12px", lg: "16px" },
                      fontWeight: "bold",
                      display: { xs: "none", md: "none", lg: "flex" },
                      '&:hover': {
                        backgroundColor: 'rgb(164, 33, 240)'
                      },
                    }}
                    onClick={handleClickOpen}
                    >
                    Iniciar sesión
                  </Button>
                  <Draw handleClickOpen={handleClickOpen} />
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </AppBar>
      {open && <FormLogin setOpen={setOpen} open={open} handleClose={handleClose} />}
      <Offset />
    </div>
  );
};

export default Nav;
