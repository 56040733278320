import { Box, Grid, TextField, Typography } from "@mui/material";
import "./Contacto.css";
import emailjs from '@emailjs/browser';
import { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { EMAILJS_PUBLIC_KEY, EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID } from "../utils/config";
import ButtonNoglax from "../components/ButtonNoglax";


const Contacto = () => {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const form = useRef();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const sendEmail = (e) => {
    e.preventDefault();
    if ([name, email, message].includes("")) {
      setLoading(false);
      toast.warn("Todos los campos son requeridos", { position: "bottom-right", });
      return;
    }
    if (!validateEmail(email)){
      setLoading(false);
      toast.warn("Por favor ingresa una direccion de correo electrónico valida!", { position: "bottom-right", });
      return;
    }
    emailjs
      .sendForm(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, form.current, {
        publicKey: EMAILJS_PUBLIC_KEY,
      })
      .then(
        () => {
          setEmail("");
          setMessage("");
          setName("");
          setLoading(false);
          toast.success("Mensaje enviado correctamente", { position: "bottom-right", });
        },
        (error) => {
          toast.error("Ha ocurrido un error", { position: "bottom-right", });
          setLoading(false)
        },
      );
  };

  return ( 
    <form ref={form}>
    <Box sx={{ 
        bgcolor:'#fff',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", 
        minHeight: "100vh",
        width: "100%",
        gap:4
      }} id="contact">
      <Typography
        variant='h4'
        sx={{
          fontWeight: 400, fontFamily: '"Rubik", sans-serif', fontSize: { xs: '30px', sm: "40px" }
        }}
      >
        Contacto
      </Typography>

      <Grid container xs={12} sx={{width:"80%"}} gap={4} justifyContent={"center"}>
          <Grid item xs={12} md={8}>
          <TextField
            color="secondary" 
            name="name"
            label="Nombre"
            value={name}
            onChange={({target}) => setName(target.value)}
            sx={{width:"100%"}}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            color="secondary"
            name="message"
            label="Mensaje"
            value={message}
            onChange={({ target }) => setMessage(target.value)}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            color="secondary"
            name="email"
            label="Correo electronico"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            sx={{ width: "100%" }}
            />
        </Grid>
        <Grid item xs={12} md={8}>
          <ButtonNoglax
            variant={"outlined"}
            size="large"
            style={{ width: "100%", fontWeight: 400, fontFamily: '"Rubik", sans-serif' }}
            onClick={(e) => {
              setLoading(true)
              sendEmail(e)
            }}
            title="Enviar"
            loading={loading}
            />
        </Grid>
      </Grid>
      <ToastContainer position="bottom_left" />
    </Box>
      </form>
   );
}
 
export default Contacto;