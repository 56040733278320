import { Box, Typography } from "@mui/material";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const InfoPerdidas = () => {
  return (
        <Box sx={{
      padding: "24px",
      fontSize: "14px",
      height: "24rem",
      maxWidth: "300px",
      minWidth: "260px",
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: "0 0 10px 0px rgba(0,0,0,0.1)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    }}>
      <Box sx={{ marginBottom: "16px" }}>
        <Typography
          align="center"
          style={{ color: "#4F5665", fontFamily: '"Rubik", sans-serif', fontSize: "18px", fontWeight: 400 }}
        >Pérdidas y comisiones</Typography>
        <Box>
          <TrendingDownIcon
            sx={{
              mt: "16px",
              padding: "20px",
              color: "rgb(164,33,230)",
              height: "60px",
              width: "60px",
              borderRadius: "50%",
              backgroundColor: "rgba(164,33,230,0.1)",
              // boxShadow: "0 0 0.9px #000"
            }}
          />
        </Box>
      </Box>
      <Box sx={{marginBottom:"74px"}}>
        <Typography
          variant="body1"
          align='justify'
          sx={{
            color: "#4F5665", fontFamily: '"Rubik", sans-serif', fontSize: "16px", fontWeight: 200,
            marginTop: "16px",
          }}
        >
          Las pérdidas son inevitables como en toda inversión, en el trading dan un lugar estadístico y porcentual, en el cual NOGLAXMAN IT trabaja con un 4% de riesgo sobre el capital invertido.
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoPerdidas;