import ButtonNoglax from "./ButtonNoglax";

const BaseFormBtn = ({ onClick, title, cancel, type = "submit", loading, onClickCancel }) => {
  return ( 
    <div style={{display: "flex", justifyContent: "center", alignItems:"center", gap: 4}}>
      { cancel && <ButtonNoglax
        title={title}
        variant="outlined"
        type={type}
        size={"medium"}
        onClick={onClickCancel}
        loading={loading}
      />}
      <ButtonNoglax
        title={title}
        variant="contained"
        type={type}
        size={"medium"}
        onClick={onClick}
        loading={loading}
      />
    </div>
   );
}
 
export default BaseFormBtn;