import { useEffect, useState } from "react";
import { API_NOGLAXMAN } from "../utils/config";
import GraphicsCount from "../components/GraphicsCount";
import { Box, Grid } from "@mui/material";
import userAccount from "../images/svg-person/user_account.svg"
import userCash from "../images/svg-person/user_cash.svg"
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { currencyFormatter } from "../utils/utils";
import DonnutChartComponent from "../components/DonnutChartComponent";

const DashboardClient = () => {

  const [amountAccounts, setAmountAccounts] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalAmmountForAccount, setTotalAmmountForAccount] = useState(0)
  const [totalProfitForAccount, setTotalProfitForAccount] = useState(0)
  const [loading, setLoading] = useState(false)

  const token = window.localStorage.getItem('token');

  const client_id = window.localStorage.getItem("client_id")

  const getGraphicsCountAccountsByUser = async () => {
    setLoading(true)
    const result = await fetch(`${API_NOGLAXMAN}/getGraphicsCountAccountsByUser/${client_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    const resolve = await result.json()
    if (resolve && resolve.length) {
      setAmountAccounts(resolve[0].quantity)
    }
    setLoading(false)
  }


  const getGraphicsAllMountByClient = async () => {
    setLoading(true)
    const result = await fetch(`${API_NOGLAXMAN}/getGraphicsAllMountByClient/${client_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    const resolve = await result.json()
    if (resolve && resolve.length) {
      const sumaTotalAmount = resolve.reduce((acumulador, object) => {
        const total = (Math.floor(object.total_amount * 100) / 100)
        return acumulador + total
      }, 0);
      setTotalAmount(sumaTotalAmount)
    }
    setLoading(false)
  }

  const getGraphicsTotalAmountForAccountByUser = async () => {
    setLoading(true)
    const result = await fetch(`${API_NOGLAXMAN}/getGraphicsTotalAmountForAccountByUser/${client_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    const resolve = await result.json()
    if (resolve && resolve.length) {
      setTotalAmmountForAccount(resolve)
    }
    setLoading(false)
  }

  const getGraphicsLastProfitForAccountByUser = async () => {
    setLoading(true)
    const result = await fetch(`${API_NOGLAXMAN}/getGraphicsLastProfitForAccountByUser/${client_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    const resolve = await result.json()
    if (resolve && resolve.length) {
      setTotalProfitForAccount(resolve)
    }
    setLoading(false)
  }

  useEffect(() => {
    getGraphicsAllMountByClient()
    getGraphicsCountAccountsByUser()
    getGraphicsTotalAmountForAccountByUser()
    getGraphicsLastProfitForAccountByUser()
    // eslint-disable-next-line
  },[])
  return ( 
    <Box sx={{ width: "100%", height: {xs: "100%", lg: "calc(100vh - 64px)"}, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#fdfdfd"}}>
      <Box sx={{ width: "75%", height: {xs:"100%", lg: "auto"}, margin: {xs: "40px 0px", lg:"0px"} }}>
        <Grid container md={12} justifyContent={"center"} alignItems={"center"} rowSpacing={{xs: 3, lg:6}} columnSpacing={5} wrap="wrap">
          <Grid item md={12} lg={6} sx={{ display: "flex", justifyContent: "center" }}>
            <GraphicsCount amount={currencyFormatter({ currency: "USD", value: totalAmount })} title={"Capital total invertido"} Icon={AttachMoneyIcon} img={userCash} iconColor={"rgb(139, 210, 146)"} iconBackground={"rgba(139, 210, 146, 0.15)"} width="35%" loading={loading} />
          </Grid>
          <Grid item md={12} lg={6} sx={{ display: "flex", justifyContent: "center" }}>
            <GraphicsCount amount={amountAccounts} title={"Cantidad de cuentas"} Icon={BadgeOutlinedIcon} img={userAccount} iconColor={"rgb(79, 168, 251)"} iconBackground={"rgb(79, 168, 251, 0.15)"} width="50%" loading={loading} />
          </Grid>
          <Grid item md={12} lg={6} sx={{ display: "flex", justifyContent: "center" }} >
            <DonnutChartComponent title={"Capital total por cuenta"} colors={["#ffb508", "#8a5cb5", "#006cea", "#c2e843", "#60c2ff"]} data={totalAmmountForAccount} loading={loading} />
          </Grid>
          <Grid item md={12} lg={6} sx={{ display: "flex", justifyContent: "center" }}>
            <DonnutChartComponent title={"Ultima ganancia por cuenta"} colors={["#ffb280", "#ffe75d", "#0874b8", "#08b95c", "#b1e929"]} data={totalProfitForAccount} loading={loading}/>
          </Grid>
        </Grid>
      </Box>
    </Box>
   );
}
 
export default DashboardClient;