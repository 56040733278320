import React from "react";
import { AppBar, Dialog, DialogContent, IconButton, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const ViewerPdf = ({ open, fileURL, setOpen, setDataSelect, title }) => {

  return (
    <Dialog 
      open={open} 
      onClose={() => {setOpen(false); setDataSelect({url: "", summary_date_start: "", summary_date_end: ""});}}
      scroll={"scroll"}
      maxWidth={"xl"}
      // TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative', backgroundColor: "#fff" }}>
        <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
          {/* <DialogTitle sx={{ color: "#7e7e7e"}}>{title}</DialogTitle> */}
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => { setOpen(false); setDataSelect({ url: "", summary_date_start: "", summary_date_end: "" }); }}
            aria-label="close"
            >
            <CloseIcon color="error" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center", padding: 0, width: "100%" }}>
        <iframe src={fileURL} style={{
          width:"100%", height:"calc(100vh - 120px)"
        }}/>
      </DialogContent>
    </Dialog>
  );
};

export default ViewerPdf;