import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupsIcon from '@mui/icons-material/Groups';
import ContactsIcon from '@mui/icons-material/Contacts';
import LoginIcon from '@mui/icons-material/Login';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import BadgeIcon from '@mui/icons-material/Badge';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";


const Draw = ( { handleClickOpen, logOut } ) => {

  const [openDrawer,  setOpenDrawer] = useState(false)

  const navigate = useNavigate()

  return ( 
    <>
      <Drawer open={openDrawer} anchor='right'
      onClose={()=> setOpenDrawer(false)}
      >
        {window.location.pathname === "/" && (
          <List>
            <ListItemButton onClick={() => {handleClickOpen(); setOpenDrawer(false);}}>
              <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
                <LoginIcon sx={{marginRight: '10px'}}/>
                <ListItemText>
                  Iniciar sesión
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <Link
              to="about" 
              spy={true} 
              smooth={true}
              duration={250}
            >
              <ListItemButton onClick={() => setOpenDrawer(false)}>
                <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
                  <ApartmentIcon sx={{ marginRight: '10px' }} />
                  <ListItemText>Acerca de</ListItemText>
                </ListItemIcon>
              </ListItemButton> 
            </Link>

            <Link
              to="we" 
              spy={true} 
              smooth={true}
              duration={250}
            >
              <ListItemButton onClick={() => setOpenDrawer(false)}>
                <ListItemIcon sx={{display: "flex", alignItems:"center"}}>
                  <GroupsIcon sx={{marginRight: '10px'}}/>
                  <ListItemText>Nosotros</ListItemText>
                </ListItemIcon>
              </ListItemButton> 
            </Link>

            <Link
              to="contact" 
              spy={true} 
              smooth={true}
              duration={250}
            >
              <ListItemButton href="#contact" onClick={() => setOpenDrawer(false)}>
                <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
                  <ContactsIcon sx={{marginRight: '10px'}}/>
                  <ListItemText>Contacto</ListItemText>
                </ListItemIcon>
              </ListItemButton>
            </Link>

          </List>
        )}
        {window.location.pathname.includes("admin") && (
          <List>
            <ListItemButton onClick={() => { navigate("/admin/dashboard"); setOpenDrawer(false);}}>
              <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
                <DashboardIcon sx={{marginRight: '10px'}}/>
                <ListItemText>
                  Dashboard
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton onClick={() => {navigate("/admin/clients"); setOpenDrawer(false);}}>
              <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
                <PeopleAltIcon sx={{marginRight: '10px'}}/>
                <ListItemText>
                  Clientes
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton onClick={() => {logOut(); setOpenDrawer(false);}}>
              <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
                <LogoutIcon sx={{marginRight: '10px'}}/>
                <ListItemText>
                  Cerrar sesión
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
          </List>
        )}
        {window.location.pathname.includes("client/") && (
          <List>
            <ListItemButton onClick={() => {navigate("/client/dashboard"); setOpenDrawer(false);}}>
              <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
                <DashboardIcon sx={{marginRight: '10px'}}/>
                <ListItemText>
                  Dashboard
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton onClick={() => {navigate("/client/accounts"); setOpenDrawer(false);}}>
              <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
                <BadgeIcon sx={{marginRight: '10px'}}/>
                <ListItemText>
                  Cuentas
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton onClick={() => {logOut(); setOpenDrawer(false);}}>
              <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
                <LogoutIcon sx={{marginRight: '10px'}}/>
                <ListItemText>
                  Cerrar sesión
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
          </List>
        )}
      </Drawer>
      <IconButton sx={{ display: { xs: "flex", md: "flex", lg: "none" }}} onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon sx={{ color: '#9567A7', display: { xs: "flex", md: "flex", lg: "none" }, fontSize:35}}/>
      </IconButton>
    </>
   );
}
 
export default Draw;