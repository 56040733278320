import React from "react";
import { Box, Typography } from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import "../pages/Contacto.css"
export const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: '#ebebeb',
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "200px"
      }}
      id="contact"
      className="seccion">
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "80%", flexDirection: "column", gap: 3 }}>
        <Box sx={{ display: "flex", gap: 4 }}>
          <a
            href="https://api.whatsapp.com/send?phone=+5491165905794&text=Hola!%20Quiero%20hacer%20crecer%20mi%20capital%20con%20Noglaxman%20%F0%9F%A4%9D.%20%20Me%20pasas%20info?%20Gracias!"
            target="blank"
            rel="noreferrer"
          >
            <WhatsAppIcon className="icon-social" sx={{ fontSize: "50px", color: "#25D366" }} />
          </a>
          <a
            href="https://www.instagram.com/noglaxman/"
            target="blank"
            rel="noreferrer"
          >
            <svg style={{ width: "50px", height: "50px" }} className="icon-social" >
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor="#833AB4" />
                  <stop offset="50%" stopColor="#DF008B" />
                  <stop offset="100%" stopColor="#FD1D1D" />
                </linearGradient>
              </defs>
              <InstagramIcon style={{ fill: 'url(#gradient)', fontSize: "50px" }} />
            </svg>
          </a>
          <a
            href="https://t.me/+w1lGMNPGVuBmMzQx"
            target="blank"
            rel="noreferrer"
          >
            <TelegramIcon className="icon-social" sx={{ fontSize: "50px", color: "#229ED9" }} />
          </a>
          <a
            href="https://es.tradingview.com/u/NOGLAXMAN/"
            target="blank"
            rel="noreferrer"
          >
            <CloudQueueIcon className="icon-social" sx={{ fontSize: "50px", color: "#6187c5" }} />
          </a>
        </Box>
        <Typography
          variant="body1"
          sx={{
            fontSize: {xs: "16px", md:"20px"},
            fontFamily: '"Rubik", sans-serif',
            fontWeight: 300,
            color: "#8c8888",
            textDecoration: "none",
          }}
        >©{new Date().getFullYear()} Copyright: noglaxman.com</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
